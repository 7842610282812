import { Button, Card, Col, Input, Row, Select } from 'antd';

import { MinusOutlined } from '@ant-design/icons';

import styles from "../index.module.scss";

export default function Product({ packages, product, removeProduct, onSearch, onChangeProduct }) {
    const onChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        if (name === 'amount' || name === 'count') {
            value = parseInt(value);
            if (value < 1) return;
        }
        onChangeProduct(product, value, name)
    }

    return packages.length && (<Card className={styles['card']} bodyStyle={{ padding: "5px 10px" }}>
        <Row justify="end">
            {product.index > 1 && <Button size='small' danger onClick={() => removeProduct(product)}><MinusOutlined /></Button>}
        </Row>
        <Row gutter={[8, 8]} >
            <Col span={6}>
                <label>Product</label><br />
                <Select
                    options={packages}
                    showSearch
                    allowClear
                    style={{ width: "100%" }}
                    optionFilterProp="label"
                    defaultValue={packages?.[0]?.value}
                    onSearch={(value) => onSearch("package", value)}
                    onChange={(value) => onChangeProduct(product, value, "package_id")}
                />
            </Col>

            <Col span={6}>
                <label>Amount</label>
                <Input
                    type='number'
                    min={1}
                    name='amount'
                    value={product.amount}
                    autoComplete={"off"}
                    onChange={onChange}
                />
            </Col>
            <Col span={6}>
                <label>Count</label>
                <Input
                    type='number'
                    min={1}
                    name="count"
                    value={product.count}
                    autoComplete={"off"}
                    onChange={onChange}
                />
            </Col>
            <Col span={6}>
                <label>Detailes</label>
                <Input.TextArea
                    name='detailes'
                    value={product.detailes}
                    autoComplete={"off"}
                    onChange={onChange}
                />
            </Col>
        </Row >
    </Card >
    )
}
