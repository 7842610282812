import React, { useState } from 'react';

import { Drawer, Form, Input, Button, notification } from 'antd';

import REQUESTS from '../../../api/requests';

export default function SendSMS({ userId, open, onClose }) {
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        if (!userId) return;

        setLoading(true);

        const body = {
            id: userId,
            message: values.message
        };

        const callback = (message) => {
            setLoading(false);
            onClose();
            form.resetFields();
            notification.success({
                message: 'Success',
                description: message
            })
        }

        const errorCallback = (error) => {
            setLoading(false);
        }

        REQUESTS.USERS.SEND_SMS(body, callback, errorCallback)
    }

    return (
        <Drawer
            title="Send SMS"
            open={open}
            onClose={onClose}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
            >
                <Form.Item
                    name="message"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your message!',
                        },
                        {
                            max: 255,
                            message: 'Message must be less than 255 characters!'
                        }
                    ]}
                    label="Message"
                >
                    <Input.TextArea maxLength={255} showCount />
                </Form.Item>
                <Form.Item>
                    <div className='center-btn'><Button htmlType='sumbit' type="primary" loading={loading} disabled={loading}>Send SMS</Button></div>
                </Form.Item>
            </Form>
        </Drawer>
    )
}
