import { DatePicker } from "antd";
import { CalendarOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;

export default function SearchByDate(setDate) {
  return {
    filterDropdown: ({ }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <RangePicker onChange={(e, dateString) => setDate(dateString)} />
      </div>
    ),
    filterIcon: (filtered) => (
      <CalendarOutlined />
    ),
  }
}
