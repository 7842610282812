import React, { createContext, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import classNames from 'classnames';

import { Calendar, Button, Row, Badge, Modal, notification } from 'antd';

import { PlusOutlined, CloseOutlined } from '@ant-design/icons';

import dayjs from 'dayjs';

import { selectUser } from '../../features/DataUserProfile/DataUserProfileSlise';

import REQUESTS from '../../api/requests';

import ReminderModal from './components/ReminderModal';
import Reminders from './components/Reminders';

import styles from "./index.module.scss";

export const Context = createContext();

export default function CalendarReminder() {
    const { admin_permissions } = useSelector(selectUser) || {};

    const [reminderModalVisible, setReminderModalVisible] = useState(false);

    const [reminders, setReminders] = useState([]);

    const hasPermission = (name) => {
        if (admin_permissions && admin_permissions["calendar"]?.[name]) {
            return admin_permissions["calendar"][name];
        }
        return false;
    }

    const getReminders = (q = {}) => {
        const query = {
            limit: 1000,
            ...q
        };

        REQUESTS.REMINDERS.GET({ query: JSON.stringify(query) }, (data) => {
            setReminders(data.rows);
        }, (err) => {
            console.log(err);
        })
    }

    const masrkAsRead = (id) => {
        REQUESTS.REMINDERS.UPDATE_STATUS({ id }, () => {
            getReminders();
        }, (err) => {
            notification.error({
                message: "Error",
                // description: err?.message,
            })
        })
    }

    const deleteReminder = (id) => {
        REQUESTS.REMINDERS.DELETE({ id }, () => {
            getReminders();
        }, (err) => {
            notification.error({
                message: "Error",
                description: err,
            })
        })
    }

    const handleClickReminder = (record, type) => {
        Modal.confirm({
            title: type == "delete" ? "Do you want to Delete Reminder?" : "Mark as Completed",
            onOk: () => {
                if (type == "delete") {
                    deleteReminder(record.id)
                } else {
                    masrkAsRead(record.id)
                }
            }
        });
    }

    const dateCellRender = (value) => {
        const formattedDate = dayjs(value).format("YYYY-MM-DD");

        const dayReminders = reminders ? reminders.filter(reminder => {
            return dayjs(reminder.date).format("YYYY-MM-DD") == formattedDate
        }) : [];

        return (
            <ul className={styles["events"]}>
                {dayReminders.map((reminder, i) => (
                    <li key={reminder?.id} className={styles['event-list-item']} >
                        <Badge
                            className={classNames(styles['reminder'], { [styles['completed']]: reminder?.is_completed })}
                            onClick={() => {
                                if (hasPermission("Update reminder")) {
                                    handleClickReminder(reminder, "mark_as_read")
                                } else {
                                    return;
                                }
                            }}
                            color="white"
                            text={reminder?.title}
                        />
                        {hasPermission("Delete") && <CloseOutlined onClick={() => handleClickReminder(reminder, "delete")} style={{ color: "red", fontSize: 11 }} />}
                    </li>
                ))}
            </ul>
        );
    };

    useEffect(() => {
        getReminders();
    }, [])

    return (
        <Context.Provider
            value={{
                hasPermission,
                getReminders,
                deleteReminder,
                reminders,
                setReminderModalVisible,
            }}
        >
            <Row justify="space-between" align='middle'>
                <h1 className='page-title'>Calendar Reminders</h1>
                {hasPermission("Add reminder") &&
                    <Button
                        type="primary"
                        onClick={() => { setReminderModalVisible(true) }}>
                        <PlusOutlined /> Add Reminder
                    </Button>
                }
            </Row>

            <Calendar dateCellRender={dateCellRender} />

            <Reminders />

            <ReminderModal
                open={reminderModalVisible}
                onClose={() => {
                    setReminderModalVisible(false);
                }}
                getReminders={getReminders}
            />
        </Context.Provider>
    )
}
