import { useState, useRef, useEffect } from "react";
import { Outlet } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import io from "socket.io-client";
import { Layout, notification, Drawer } from "antd";
import useResize from "../hooks/use-resize";
import REQUESTS from "../api/requests";
import Sidbar from "./components/Sidebar";
import HeaderComponent from "./components/Header";
import {
  selectchat,
  editchat,
  selectActivId,
  selectMeesageCount,
  editMeesageCount,
} from "../features/chat/chatSlise";
import { selectappInfo } from "../features/appInfoSlice/AppInfoSlice";
import { selectwidgets } from "../features/widgets/selector";
import { editUser } from "../features/DataUserProfile/DataUserProfileSlise";
import CONSTANTS from "../config";

import "../index.scss";
import classes from "./index.module.scss";

const { Header, Content } = Layout;

const DashboardPage = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [open, setOpen] = useState(false);

  const socketRef = useRef(null);

  const dispatch = useDispatch();

  const newChat = useSelector(selectchat);
  const appInfo = useSelector(selectappInfo);
  const activeChat = useSelector(selectActivId);
  const messageCount = useSelector(selectMeesageCount);
  const widgets = useSelector(selectwidgets);
  const resize = useResize()

  const showDrawer = () => {
    setOpen(true);
  };

  const openNotification = () => {
    const args = {
      message: "Chat",
      description: "New message",
      duration: 5,
    };
    notification.open(args);
  };

  const getProfile = () => {

    function collback(data) {

      let permissions = {};

      data.admin_permissions.forEach((item) => {
        if (!permissions[item.group]) {
          permissions[item.group] = {};
        };

        permissions[item.group][item.name] = item.enabled;
      });

      dispatch(editUser({ ...data, admin_permissions: permissions }));
    }

    function errorCollback(data) {
      console.error("error : ", data)
    }

    REQUESTS.PROFILE.GET(collback, errorCollback);
  };

  useEffect(() => {
    if (activeChat === "") {
      dispatch(editMeesageCount(messageCount + 1));
    }
    if (activeChat === "" && messageCount >= 0) {
      openNotification();
    }
  }, [newChat]);

  useEffect(() => {
    if (resize < 790) {
      setCollapsed(false);
    }
  }, [resize]);

  useEffect(() => {

    getProfile();

    socketRef.current = io(CONSTANTS.API_HOST, {
      extraHeaders: {
        Authorization: `Bearer ${localStorage.getItem("TOKEN")}`,
      },
    });

    return () => {
      if (socketRef) {
        socketRef.current.disconnect();
      }
    };

  }, []);

  useEffect(() => {
    if (widgets?.chat?.enabled === "true") {
      socketRef.current.on("message", () => {
        dispatch(editchat());
      });
    }
  }, [widgets]);

  return (
    <Layout className="layout" style={{ minHeigh: "100vh" }}>
      {resize > 800 ? (
        <Sidbar collapsed={collapsed} />
      ) : (
        <Drawer
          placement="left"
          closable={false}
          onClose={() => setOpen(false)}
          open={open}
          title={null}
          bodyStyle={{ padding: 0 }}
          width={290}
        >
          <Sidbar collapsed={collapsed} setOpen={setOpen} />
        </Drawer>
      )}

      <Layout className={classes["site-layout"]}>
        <Header
          className="site-layout-background"
          style={{
            padding: 0,
            color: "black",
            height: "63px",
          }}
        >
          <HeaderComponent
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            showDrawer={showDrawer}
          />
        </Header>
        <Content
          className={classes["site-layout-background"]}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default DashboardPage;
