import React, { useContext, useEffect, useState } from 'react';

import { Modal, Table } from 'antd';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import getMyDate from '../../../components/getMyDate';
import TableButtons from '../../../components/TableButtons';
import TextEllipsisTooltip from '../../../components/TextEllipsisTooltip';
import SearchByDate from '../../../components/SearchByDate';

import { getColumnSearchProps } from '../../../utils';

import { Context } from '../index';

export default function Reminders() {
    const { hasPermission, deleteReminder, reminders, setReminderModalVisible, getReminders } = useContext(Context);

    const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: reminders.length });
    const [date, setDate] = useState(false);
    const [search, setSearch] = useState({});

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => {
                const { current, pageSize } = pagination;
                return (current - 1) * pageSize + index + 1;
            }
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            ...getColumnSearchProps('title'),
            render: (text) => text ? <TextEllipsisTooltip text={text} width={100} /> : "N/A"
        },
        {
            title: 'Description',
            dataIndex: 'message',
            key: 'message',
            ...getColumnSearchProps('message'),
            render: (text) => text ? <TextEllipsisTooltip text={text} /> : "N/A"
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            ...SearchByDate(setDate),
            render: (text) => text ? getMyDate(text) : "N/A"
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            align: "center",
            render: (text, record) => {
                const buttonsConfig = [
                    {
                        permitionKey: "Update reminder",
                        key: 'edit',
                        text: 'Edit',
                        icon: <EditOutlined />
                    },
                    {
                        permitionKey: "Delete",
                        key: 'delete',
                        text: 'Delete',
                        icon: <DeleteOutlined />
                    }
                ];

                const buttons = buttonsConfig.filter(({ permitionKey }) => hasPermission(permitionKey));

                return <TableButtons
                    handleMenuClick={(event) => handleActions(event, record)}
                    buttons={buttons}
                />
            }
        }
    ]

    const handleTableChange = (pagination, filters, sorter) => {
        setPagination(pagination);
        setSearch(filters);
    }

    const handleActions = (event, record) => {
        switch (event.key) {
            case 'edit':
                setReminderModalVisible(record);
                break;
            case 'delete':
                Modal.confirm({
                    title: 'Do you want to delete this reminder?',
                    onOk: () => {
                        deleteReminder(record.id);
                    }
                });
                break;
        }
    }

    useEffect(() => {
        let timeout = setTimeout(() => {
            const query = {}

            if (date && date[0] && date[1]) {
                query.between = {
                    date: {
                        from: date[0] + " 00:00",
                        to: date[1] + " 23:59",
                    }
                }
            };

            if (search?.title && search.title[0]) {
                query.search = {
                    title: search.title[0]
                }
            };

            if (search?.message && search.message[0]) {
                query.search = {
                    message: search.message[0]
                }
            };

            getReminders(query);

        }, 500);

        return () => clearTimeout(timeout);

    }, [date, search]);

    return (
        <div style={{ marginTop: 20 }}>
            <h1 className='page-title'>Reminders</h1>
            <Table
                onChange={handleTableChange}
                pagination={{
                    showSizeChanger: true,
                    ...pagination,
                }}
                dataSource={reminders}
                columns={columns}
                size='small'
                scroll={{ x: "max-content", }}
            />
        </div>
    )
}
