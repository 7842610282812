import React, { useEffect, useState } from 'react';

import { Table, DatePicker, Empty } from 'antd';

import { CalendarOutlined, ExpandAltOutlined, TagOutlined, DeleteOutlined } from '@ant-design/icons';

import ICONS from '../../../config/icons';

import REQUESTS from '../../../api/requests';

import getMyDate from '../../../components/getMyDate';

import macMaskInput from '../../../components/macMaskInput';

import getColumnSearchProps from '../../../components/getColumnSearchProps';

import DeviceManagerDrawer from '../../deviceManager/components/DeviceManagerDrawer';
import ActivationDrawer from '../../deviceManager/components/ActivationDrawer';

import TableButtons from '../../../components/TableButtons';

import showPropsConfirm from '../../../components/showPropsConfirm';

import styles from "../index.module.scss"

const { RangePicker } = DatePicker;

export default function UserDevices({ current }) {
    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);

    const [limit, setLimit] = useState(10);

    const [total, setTotal] = useState(0);

    const [sort, setSort] = useState(["online", "ASC"]);

    const [date, setDate] = useState("");

    const [expiredDate, setExpiredDate] = useState("");

    const [activationExpired, setActivationExpired] = useState();

    const [search, setSearch] = useState({});

    const [show, setShow] = useState(false);

    const [currentDevice, setCurrentDevice] = useState({});

    const [activationVisible, setActivationVisible] = useState();

    const [editDevice, setEditDevice] = useState(null);

    const deleteTable = (id) => {
        const callback = (data) => {
            getUserDevices();
        };

        const errorCallback = (data) => { };

        REQUESTS.DEVICE.DELETE_DEVICE(id, callback, errorCallback);
    };

    const deactivateDevice = (id) => {
        const body = { id }

        function callback(data) {
            getUserDevices();
        }

        function errorCallback(err) { }

        REQUESTS.DEVICE_DEACTIVEATE(body, callback, errorCallback)
    }

    const free_trial = (id) => {
        const body = {
            id,
        };
        const callback = (data) => {
            getUserDevices();
        };

        const errorCallback = (data) => { };

        REQUESTS.DEVICE.FREE_TRIAL(body, callback, errorCallback);
    };

    const handleMenuClick = (e, item) => {
        switch (e.key) {
            case "free_trial":
                showPropsConfirm(
                    item.id,
                    free_trial,
                    loading,
                    "Free trial",
                    "#28857c",
                    "Are you sure you want to free trial?",
                    "CANCEL"
                );
                break;
            case "activate":
                setEditDevice(item);
                setActivationVisible(true);
                break;

            case "deactivate":
                showPropsConfirm(
                    item.id,
                    deactivateDevice,
                    loading,
                    "DEACTIVATE",
                    "#bf4342",
                    "Are you sure you want to deactivate device?",
                    "CANCEL"
                );
                break;

            case "delete":
                showPropsConfirm(
                    item.id,
                    deleteTable,
                    loading,
                    "DELETE",
                    "#bf4342",
                    "Are you sure you want to delete?",
                    "CANCEL"
                );
                break;
            default:
                break;
        }
    };

    const columns = [
        {
            title: "#",
            dataIndex: "id",
            key: "id",
            align: "center",
            render: (text, record, index) => {
                if (currentPage === 1) {
                    return index + 1;
                } else {
                    return limit * (currentPage - 1) + index + 1;
                }
            },
        },

        {
            title: "Mac",
            align: "center",
            dataIndex: "mac",
            ...macMaskInput(),
        },
        {
            title: "Model",
            align: "center",
            dataIndex: "model",
            key: "model",
            ...getColumnSearchProps("model"),
            render: (text, record) => {
                return record && <div>
                    <div>{record.model}</div>
                    <div>{record.os_version}</div>
                </div>
            }
        },
        {
            title: "App version",
            align: "center",
            dataIndex: "app_version",
            key: "app_version",
            ...getColumnSearchProps("app_version"),
            render: (text, record, index) => {
                return record ? record.app_version : "-";
            },
        },
        {
            title: "Online",
            align: "center",
            dataIndex: "online",
            sorter: true,
            render: (record, iteam, index) => {
                return iteam.online !== false ? (
                    <p>Online</p>
                ) : iteam.last_online ? (
                    getMyDate(iteam.last_online)
                ) : (
                    "N/A"
                );
            },
        },
        {
            title: "Ip",
            align: "center",
            dataIndex: "ip",
            key: "ip",
            ...getColumnSearchProps("ip"),
        },
        {
            title: "Created date",
            align: "center",
            dataIndex: "createdAt",
            sorter: true,
            render: (record) => {
                if (record) {
                    return getMyDate(record);
                } else {
                    return "N/A";
                }
            },
            filterDropdown: () => (
                <div
                    style={{
                        padding: 8,
                    }}
                >
                    <RangePicker
                        size="small"
                        onChange={(e, dateString) => setDate(dateString)}
                        renderExtraFooter={() => "extra footer"}
                    />
                </div>
            ),
            filterIcon: (filtered) => (
                <CalendarOutlined />
            ),
        },
        {
            title: "",
            width: "60px",
            dataIndex: "action",
            align: "center",
            render: (text, record, index) => (
                <div onClick={(e) => e.stopPropagation()}>
                    <TableButtons
                        handleMenuClick={(e) => handleMenuClick(e, record)}
                        buttons={[
                            {
                                key: "free_trial",
                                text: "Free trial",
                                icon: <ExpandAltOutlined />,
                            },
                            {
                                key: "activate",
                                text: "Activate",
                                icon: <TagOutlined />,
                            },
                            {
                                key: "deactivate",
                                text: "Deactivate",
                                // icon: (record.free_trial == 1 || record.payed) ? ICONS.DEACTIVE : ICONS.DEACTIVE_2,
                                // disabled: !(record.free_trial == 1 || record.payed),
                                icon: ICONS.DEACTIVE
                            },
                            {
                                key: "delete",
                                text: "Delete",
                                icon: <DeleteOutlined />,
                            },
                        ]}
                    />
                </div>
            ),
        },
    ];

    const getUserDevices = () => {
        setLoading(true);

        const query = {
            page: currentPage,
            limit,
            pagination: 1,
            sort: JSON.stringify(sort),
            search: {},
            filter: {
                user_id: current.id,
            }
        };

        if (search.user) {
            query.search["user"] = search.user[0];
        }

        if (search.code) {
            query.search["code"] = search.code[0];
        }

        if (search.mac) {
            query.search["mac"] = search.mac[0];
        }

        if (search.model) {
            query.search["model"] = search.model[0];
        }

        if (search.app_version) {
            query.search["app_version"] = search.app_version[0];
        }

        if (search.ip) {
            query.search["ip"] = search.ip[0];
        }

        if (query.search) {
            query.search = JSON.stringify(query.search);
        }

        if (search.status) {
            if (search.status[0] === "payed") {
                query.filter["payed"] = true;
            }

            if (search.status[0] === "free_trial") {
                query["free_trial"] = true;
            }

            if (search.status[0] === "deactivated") {
                query["deactivated"] = true;
            }

            if (search.status[0] === "none") {
                query["none"] = true;
            }
        }

        query.filter = JSON.stringify(query.filter);

        let dataArray = [
            { date: date, betweenDate: "createdAt" },
            { date: expiredDate, betweenDate: "free_trial_expired" },
            { date: activationExpired, betweenDate: "activation_expired" },
        ];

        for (let i = 0; i < dataArray.length; i++) {
            if (dataArray[i].date && dataArray[i].date[0]) {
                let to = new Date(dataArray[i].date[1]);
                to.setDate(to.getDate() + 1);
                if (query.between) {
                    query.between = JSON.stringify({
                        ...JSON.parse(query?.between),
                        [dataArray[i].betweenDate]: {
                            from: new Date(dataArray[i].date[0]),
                            to: new Date(to),
                        },
                    });
                } else {
                    query.between = JSON.stringify({
                        [dataArray[i].betweenDate]: {
                            from: new Date(dataArray[i].date[0]),
                            to: new Date(to),
                        },
                    });
                }
            }
        }

        REQUESTS.DEVICE.GET(query, (data) => {
            setTotal(data.count);
            if (data.rows.length === 0 && currentPage > 1) {
                setCurrentPage((current) => current - 1);
            } else {
                setCurrentPage(data.currentPage);
            }
            setLoading(false);

            setData(data.rows);

        });
    };

    const handleTableChange = (pagination, filters, sorter) => {
        if (sorter.field) {
            setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
        }
        setSearch(filters);

        setCurrentPage(pagination.current);
        setLimit(pagination.pageSize);
    };

    useEffect(() => {
        let timout = null;

        if (current && current.id) {
            timout = setTimeout(() => {
                getUserDevices();
            }, 500);
        } else {
            setData([]);
        }

        return () => {
            clearTimeout(timout);
        };
    }, [search, currentPage, limit, date, sort, expiredDate, activationExpired]);

    return (
        <div>
            <h2 className="page-title">Devices</h2>
            <Table
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (e) => {
                            setShow(true);
                            setCurrentDevice(record);
                        },
                    };
                }}
                loading={loading}
                rowKey="id"
                columns={columns}
                dataSource={data}
                onChange={handleTableChange}
                pagination={{
                    position: ["bottomCenter"],
                    current: currentPage,
                    total: total,
                    pageSize: limit,
                    showSizeChanger: true,
                }}
                className={styles['table-rows']}
                size="small"
                scroll={{ x: "max-content" }}
                locale={{
                    emptyText: (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
                    ),
                }}
            />
            <DeviceManagerDrawer
                userInfo={currentDevice}
                visible={show}
                onClose={() => setShow(false)}
            />

            <ActivationDrawer
                visible={activationVisible}
                // onClose={closeDActivationrawer}
                current={editDevice}
                getData={getUserDevices}
            />
        </div>
    )
}
