import React from "react";
import classes from './style/tableButtons.module.scss'
import { EllipsisOutlined } from '@ant-design/icons'
import { Dropdown, Menu, Button } from "antd";

export default function TableButtons({ handleMenuClick, buttons = [], disabled = null, props }) {
    return (
        <Dropdown
            destroyPopupOnHide={true}
            trigger={["click"]}
            placement="bottomRight"
            className="table-action-button"
            overlay={
                <Menu onClick={handleMenuClick} style={{ minWidth: 130 }}>
                    {buttons.filter((item) => item).map((item) => {
                        return (
                            <Menu.Item
                                disabled={item.disabled}
                                className={classes["action_button"]}
                                key={item.key}
                                icon={item.icon}>
                                {item.text}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            }
            {...props}
        >
            <Button icon={<EllipsisOutlined />} />
        </Dropdown>
    );
}

