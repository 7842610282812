import { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { Button, Modal, notification, Row, Table, Tag } from "antd";

import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

import REQUESTS from "../../api/requests";

import { getColumnSearchProps } from "../../utils";

import getMyDate from "../../components/getMyDate";
import TableButtons from "../../components/TableButtons";
import SearchByDate from "../../components/SearchByDate";

import { selectUser } from "../../features/DataUserProfile/DataUserProfileSlise";

import AddTasks from "./components/AddTasks";
import TextEllipsisTooltip from "../../components/TextEllipsisTooltip";

export default function Tasks() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0
    });

    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState({});
    const [date, setDate] = useState(false);
    const [current, setCurrent] = useState(null);

    const profile = useSelector(selectUser) || {};
    const { admin_permissions } = profile;

    const hasPermission = (name) => {
        if (admin_permissions && admin_permissions["tasks"]?.[name]) {
            return admin_permissions["tasks"][name];
        }
        return false;
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => {
                const { current, pageSize } = pagination;
                return (current - 1) * pageSize + index + 1;
            }
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            align: "center",
            ...getColumnSearchProps('title'),
            render: (text) => <TextEllipsisTooltip text={text} />
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            align: "center",
            ...getColumnSearchProps('description'),
            render: (text) => <TextEllipsisTooltip text={text} />
        },
        {
            title: 'Assigned To',
            dataIndex: 'admin',
            key: 'admin',
            align: "center",
            render: (text, record) => {

                const { name, surname, email } = record.admin || {};



                return record.admin ? <div>
                    <div>{name} {surname}</div>
                    <div>{`(${email})`}</div>
                </div> : profile ? <div>
                    <div>{profile.name} {profile.surname}</div>
                    <div>{`(${profile.email})`}</div>
                </div> : "N/A"
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: "center",
            filters: [
                {
                    text: 'In progress',
                    value: 'In progress'
                },
                {
                    text: 'Completed',
                    value: 'Completed'
                }
            ],
            filterMultiple: false,
            render: (text) => <Tag color={text == 'In progress' ? 'purple' : 'orange'}>{text}</Tag>
        },
        {
            title: 'Due Date',
            dataIndex: 'due_date',
            key: 'due_date',
            align: "center",
            ...SearchByDate(setDate),
            render: (text) => text ? getMyDate(text) : "N/A"
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            align: "center",
            render: (text, record) => {
                const buttonsConfig = [
                    {
                        permitionKey: "Update task",
                        key: 'edit',
                        text: 'Edit',
                        icon: <EditOutlined />
                    },
                    {
                        permitionKey: "Delete",
                        key: 'delete',
                        text: 'Delete',
                        icon: <DeleteOutlined />
                    }
                ];

                const buttons = buttonsConfig.filter(({ permitionKey }) => hasPermission(permitionKey));

                return <TableButtons
                    handleMenuClick={(event) => handleActions(event, record)}
                    buttons={buttons}
                />
            }
        }
    ]

    const deleteTask = (id) => {
        const onOk = () => {
            REQUESTS.TASKS.DELETE({ id }, (data) => {
                getTasks();
                notification.success({
                    message: 'Task deleted successfully',
                })
            }, (error) => {
                notification.error({
                    message: 'Error',
                    description: "Something went wrong"
                })
            })
        }

        Modal.confirm({
            title: 'Are you sure you want to delete this task?',
            onOk,
        });
    }

    const handleActions = (event, record) => {
        switch (event.key) {
            case 'edit':
                setOpen(true);
                setCurrent(record);
                break;
            case 'delete':
                deleteTask(record.id);
                break;
            default:
                break;
        }
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPagination({
            ...pagination,
            current: pagination.current,
            pageSize: pagination.pageSize
        })

        setSearch(filters);
    }

    const getTasks = () => {
        setLoading(true);

        const query = {
            limit: pagination.pageSize,
            page: pagination.current,
        }

        if (search.title && search.title[0]) {
            query.search = {
                title: search.title[0]
            }
        }

        if (search.description && search.description[0]) {
            query.search = {
                description: search.description[0]
            }
        }

        if (search.status && search.status[0]) {
            query.filter = {
                status: search.status[0]
            }
        }

        if (date && date[0] && date[1]) {
            query.between = {
                due_date: {
                    from: date[0] + " 00:00",
                    to: date[1] + " 23:59",
                },
            };
        }

        function callback(data) {
            setLoading(false)
            setData(data.rows)
            setPagination({
                ...pagination,
                total: data.count
            })
        }

        function errorCallback(error) {
            setLoading(false)
        }

        REQUESTS.TASKS.GET({ query: JSON.stringify(query) }, callback, errorCallback)
    }

    useEffect(() => {

        let timoutId = setTimeout(getTasks, 500);

        return () => clearTimeout(timoutId);

    }, [pagination.current, pagination.pageSize, search, date])

    return (
        <div>
            <Row justify="space-between" align='middle'>
                <h1 className='page-title'>Tasks</h1>
                {hasPermission("Add task") &&
                    <Button
                        type="primary"
                        onClick={() => { setOpen(true) }}>
                        <PlusOutlined /> Add Task
                    </Button>
                }
            </Row>

            <Table
                loading={loading}
                columns={columns}
                dataSource={data}
                scroll={{ x: "max-content" }}
                onChange={handleTableChange}
                pagination={{
                    position: ["bottomCenter"],
                    showSizeChanger: true,
                    ...pagination
                }}
                size="small"
            />
            <AddTasks
                open={open}
                onClose={() => {
                    if (current) setCurrent(null)
                    setOpen(false)
                }}
                current={current}
                getTasks={getTasks}
            />
        </div>
    )
}
