export function editwidgets(newwidgets) {
  return {
    type: "add_widgets",
    payload: {
      widgets: newwidgets,
    },
  };
}

export function editPaymentSettings(data) {
  return {
    type: "payment_settings",
    payload: data
  };
}
