
import PayPalButtonComponent from '../../../components/PayPalButtonComponent'
import StripeButtonComponent from '../../../components/StripeButtonComponent'

export default function PayByCard({ current, getData, onCancel }) {
    const { id, amount, pay_amount, status } = current;
    return (
        <>
            <div>
                <h2>Pay By Paypal</h2>
                <PayPalButtonComponent id={id} amount={status == "half_paid" ? amount - pay_amount : amount} />
            </div>
            {/* <div>
                <h2>Pay By Stripe</h2>
                <StripeButtonComponent id={id} />
            </div> */}
        </>
    )
}
