import React, { useCallback, useEffect, useState } from 'react';

import { Card, DatePicker, Badge } from 'antd';

import REQUESTS from '../../../api/requests';

import moment from 'moment';

import ICONS from '../../../config/icons';

import styles from "../index.module.scss";

export default function InvoiceStatistics() {
    const [data, setData] = useState(null);
    const [week, setWeek] = useState(null);
    const [year, setYear] = useState(null);
    const [month, setMonth] = useState(null);

    const onChange = (type, dateString) => {
        if (dateString) getData(type, dateString)

        switch (type) {
            case "weekly": return setWeek(dateString);
            case "monthly": return setMonth(dateString);
            case "yearly": return setYear(dateString);
        }
    };

    const getData = useCallback((type, data) => {
        const query = { type, start_date: data }

        const callback = (responseData) => {
            setData(prevData => ({
                ...prevData,
                [type]: responseData
            }));
        }

        REQUESTS.INVOICES_STATISTICS(query, callback);

    }, [week, month, year])

    const renderData = (type) => {
        return data?.[type] && <div className={styles['data']}>
            {Object.entries(data[type]).map(([key, value], index) => {
                return <div key={index}>
                    <span><strong>{key}:</strong> {value}</span>
                </div>
            })}
        </div>
    }

    useEffect(() => {
        const date = new Date()
        const year = date.getFullYear()
        let month = date.getMonth() + 1
        const day = date.getDate()
        month = month > 9 ? month : `0${month}`

        if (data) return;

        onChange("weekly", `${year}-${month}-${day}`);
        onChange("monthly", `${year}-${month}-01`);
        onChange("yearly", `${year}-01-01`);

    }, [])

    return (<div className={styles['invoice-statistics']}>
        <h2 className={styles['title']}>Invoice statistics</h2>
        <div className={styles['statistcs-cards']}>
            <Badge.Ribbon text="Year" color='orange'>
                <Card className={styles['card']} size='small'>
                    <div className={styles['cards-header']}>
                        <div className={styles['icon-container']}>{ICONS.WALLET}</div>
                        <DatePicker
                            size='small'
                            onChange={(date, dateString) => onChange("yearly", dateString ? dateString + "-01-01" : dateString,)}
                            defaultValue={moment(new Date())} format={"YYYY"}
                            picker="year" />
                    </div>
                    {renderData("yearly")}
                </Card>
            </Badge.Ribbon>
            <Badge.Ribbon text="Month" color='#007378'>
                <Card className={styles['card']} size='small'>
                    <div className={styles['cards-header']}>
                        <div className={styles['icon-container']}>{ICONS.WALLET}</div>
                        <DatePicker
                            size='small'
                            onChange={(date, dateString) => onChange("monthly", dateString ? dateString + "-01" : dateString)}
                            defaultValue={moment(new Date())} format={"YYYY-MM"}
                            picker="month" />
                    </div>
                    {renderData("monthly")}
                </Card>
            </Badge.Ribbon>
            <Badge.Ribbon text="Week" color='#f1be46'>
                <Card className={styles['card']} size='small' >
                    <div className={styles['cards-header']}>
                        <div className={styles['icon-container']}>{ICONS.WALLET}</div>
                        <DatePicker
                            size='small'
                            onChange={(date, dateString) => onChange("weekly", dateString,)}
                            format={"YYYY-MM-DD"}
                            defaultValue={moment(new Date(), "YYYY-MM-DD")}
                        />
                    </div>
                    {renderData("weekly")}
                </Card>
            </Badge.Ribbon>
        </div>
    </div >)
}
