import { useEffect, useState } from 'react';

import { Drawer, Table } from 'antd';

import REQUESTS from '../../../api/requests';

import getMyDate from '../../../components/getMyDate';
import useResize from '../../../hooks/use-resize';
import { getColumnSearchProps } from '../../../utils';
import SearchByDate from '../../../components/SearchByDate';

export default function PaymentsDrawer({ invoice, open, onClose }) {
    const [data, setData] = useState([]);

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 10,
        showSizeChanger: true,
    });

    const [search, setSearch] = useState("");

    const [date, setDate] = useState(null);

    const [loading, setLoading] = useState(false);

    const resize = useResize();

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => index + 1 + pagination.pageSize * (pagination.current - 1),
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            align: "center"
        },
        {
            title: 'Payment Method',
            dataIndex: 'method',
            key: 'method',
            align: "center",
            ...getColumnSearchProps('method'),
        },
        {
            title: 'Payment Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: "center",
            ...SearchByDate(setDate),
            render: (text) => getMyDate(text)
        },
    ]

    const getPayments = () => {

        if (!invoice?.id) return;

        setLoading(true);

        const query = {
            page: pagination.current,
            limit: pagination.pageSize,

            filter: {
                invoice_id: invoice.id
            }
        }

        if (search && search.method?.[0]) {
            query.filter = {
                method: search.method[0],
            }
        }

        if (date && date[0] && date[1]) {
            query.between = {
                "createdAt": {
                    from: date[0] + " 00:00:00",
                    to: date[1] + " 23:59:59",
                },
            };
        }

        function callback(response) {
            setLoading(false);
            setData(response.rows);
            setPagination((prev) => ({
                ...prev,
                total: response.count,
            }));
        }

        function errorCallback(error) {
            setLoading(false);
        }

        REQUESTS.INVOICES_PAYMENTS({ query: JSON.stringify(query) }, callback, errorCallback)
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            pageSize: pagination.pageSize,
        }));
        setSearch(filters);
    }

    useEffect(() => {
        if (!open || !invoice) {
            setData([]);
            return;
        }

        let timeoutId = setTimeout(getPayments, 500);

        return () => clearTimeout(timeoutId);

    }, [open, invoice, search, date, pagination.current, pagination.pageSize]);

    return (
        <Drawer
            title="Payments"
            closable={true}
            onClose={onClose}
            open={open}
            width={resize > 600 ? 600 : "100%"}
            destroyOnClose={true}
        >
            <Table
                bordered
                loading={loading}
                dataSource={data}
                columns={columns}
                onChange={handleTableChange}
                pagination={pagination}
                size='small'
                scroll={{ x: "max-content" }}
            />
        </Drawer>
    )
}
