import { useEffect } from 'react';

import { Button, Form, Input, InputNumber, notification } from 'antd';

import REQUESTS from '../../../api/requests';

export default function PayByCash({ current, getData, onCancel }) {
    const [form] = Form.useForm();

    const { amount, pay_amount, status } = current;

    const onFinish = (values) => {
        const { description, pay_amount } = values;

        const body = {
            id: current.id,
            pay_amount: +pay_amount,
        }

        if (description) {
            body.description = description
        }

        const callback = (data) => {
            getData();
            onCancel()
            form.resetFields();
            notification.success({
                message: 'Success',
                description: data
            })
        }
        const errorCallback = (error) => {
            notification.error({
                message: 'Error',
                description: error.message
            })
        }

        REQUESTS.PAY_BY_CASH(body, callback, errorCallback)
    }

    useEffect(() => {
        if (!current) return;

        form.setFieldsValue({
            pay_amount: status == "half_paid" ? amount - pay_amount : amount
        })
    }, [current])

    return (
        <Form
            form={form}
            layout='vertical'
            onFinish={onFinish}
        >
            <Form.Item
                label="Amount"
                name="pay_amount"
                rules={[
                    { required: true, message: 'Please input the amount!' },
                    {
                        type: 'number',
                        max: status == "half_paid" ? amount - pay_amount : amount,
                        validator: (rule, value) => {
                            let a = status == "half_paid" ? amount - pay_amount : amount;
                            if (value > a) {
                                return Promise.reject(`Inputed value must be less than or equal to ${a}`);
                            }
                            return Promise.resolve();
                        }
                    }
                ]}
            >
                <Input type='number' />
            </Form.Item>
            <Form.Item label="Description" name="description">
                <Input.TextArea />
            </Form.Item>
            <Form.Item className={'float-right-btn'}>
                <Button type="primary" htmlType='submit' className={'float-right-btn'}>Pay</Button>
            </Form.Item>
        </Form>
    )
}
