
import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { Drawer, Button, Form, Input, Select, DatePicker, notification } from 'antd';

import moment from 'moment';

import REQUESTS from '../../../api/requests';
import { selectUser } from '../../../features/DataUserProfile/DataUserProfileSlise';

export default function AddTasks({ open, onClose, current, getTasks }) {
    const [loading, setLoading] = useState(false);
    const [admins, setAdmins] = useState([]);

    const { id } = useSelector(selectUser)

    const [form] = Form.useForm();

    const getAdmins = () => {
        const query = {
            pagination: false
        };

        const callback = (res) => {
            let data = res.map((item) => {
                return {
                    label: `${item.name} ${item.surname} (${item.email})`,
                    value: item.id
                }
            })
            setAdmins(data);
        }

        REQUESTS.ADMINS.GET({ query: JSON.stringify(query) }, callback)
    }

    const onFinish = (values) => {

        setLoading(true);

        const { title, description, due_date, to_admin_id, status } = values;

        let formattedDate = due_date ? new Date(due_date) : null;

        const body = {
            title,
            description,
            status,
        }

        if (due_date) {
            body.due_date = formattedDate;
        }

        if (id != to_admin_id && to_admin_id) {
            body.to_admin_id = to_admin_id
        }

        if (current) {
            body.id = current.id;
            body.due_date = formattedDate;

            REQUESTS.TASKS.PUT(body, (res) => {
                setLoading(false);
                getTasks();
                onClose();

                notification.success({
                    message: 'Task updated successfully',
                });

            }, (err) => {
                setLoading(false);

                notification.error({
                    message: 'Error',
                    description: err
                });
            })

        } else {
            REQUESTS.TASKS.POST(body, (res) => {
                setLoading(false);

                getTasks();

                onClose();

                notification.success({
                    message: 'Task added successfully',
                });

            }, (err) => {
                setLoading(false);

                notification.error({
                    message: 'Error',
                    description: err
                });
            })
        }
    };

    useEffect(() => {
        if (!open && form) {
            form.resetFields();
            setLoading(false);
        } else {
            getAdmins()
        }
    }, [open]);

    useEffect(() => {
        if (open && current) {
            form.setFieldsValue({
                title: current.title,
                description: current.description,
                to_admin_id: current.to_admin_id || id,
                status: current.status,
                due_date: current.due_date ? moment(current.due_date) : null,
            })
        }
    }, [current, open]);

    return (
        <Drawer
            title={current ? "Edit Task" : "Add Task"}
            onClose={onClose}
            open={open}
            destroyOnClose={true}
        >
            <Form
                form={form}
                onFinish={onFinish}
                layout='vertical'
            >
                <Form.Item
                    name='title'
                    label="Title"
                    rules={[
                        { required: true, message: 'Please input title!' },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name='description'
                    label="Description"
                    rules={[
                        { required: true, message: 'Please input description!' },
                        { max: 255, message: 'Max 255 characters' }
                    ]}
                >
                    <Input.TextArea maxLength={255} showCount />
                </Form.Item>
                <Form.Item
                    name="to_admin_id"
                    label="Assigned to"
                >
                    <Select
                        showSearch
                        placeholder="Select Admin"
                        allowClear
                        options={admins}
                        filterOption={(input, option) =>
                            option.label.toLowerCase().includes(input.toLowerCase())
                        }
                    >
                    </Select>
                </Form.Item>
                <Form.Item
                    name="status"
                    label="Status"
                    rules={[
                        { required: true, message: 'Please select status!' },
                    ]}
                >
                    <Select
                        placeholder="Select Status"
                        allowClear
                        allowSearch
                        options={[
                            { label: 'Completed', value: 'Completed' },
                            { label: "In progress", value: "In progress" }
                        ]}
                    />
                </Form.Item>
                <Form.Item name='due_date' label="Due Date">
                    <DatePicker
                        showTime={{
                            format: 'HH:mm',
                        }}
                        format="YYYY-MM-DD HH:mm"
                        placeholder="Select Date and Time"
                        style={{ width: '100%' }}
                        disabledDate={(current) => {
                            return current && current < moment().startOf('day');
                        }}
                    />
                </Form.Item>
                <Form.Item className='center-btn'>
                    <Button htmlType='submit' type='primary' loading={loading}>Submit</Button>
                </Form.Item>
            </Form>
        </Drawer>
    )
}
