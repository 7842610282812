import React, { useEffect, useState } from 'react';

import { DatePicker, Drawer, Form, Input, Select, Button, Row, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import dayjs from 'dayjs';

import debounce from 'lodash.debounce';
import REQUESTS from '../../../api/requests';

import useResize from '../../../hooks/use-resize';

import ErrorMessage from "../../../components/ErrorMessage";

import Product from './Product';

export default function InvoicesDrawer({ open, onClose, getData }) {
    const [form] = Form.useForm();

    const [users, setUsers] = useState([]);

    const [packages, setPackages] = useState([]);

    const [products, setProducts] = useState([]);

    const [message, setMessage] = useState({ type: false, text: "" });

    const resize = useResize()

    const onFinish = (values) => {
        setMessage({ type: true, text: "" });

        const { user_id, title, send_date, note } = values;

        let productsArr = products?.map((pack) => {
            return {
                package_id: pack.package_id,
                count: pack.count,
                amount: pack.amount,
                total_amount: pack.count * pack.amount,
                detailes: pack.detailes
            }
        });

        const body = {
            user_id,
            title,
            amount: productsArr.reduce((sum, item) => {
                sum += item.total_amount;
                return sum;
            }, 0),
            pdf_filename: "test pdf",
            packages: productsArr
        }

        if (send_date) {
            body["send_date"] = dayjs(send_date).format("YYYY-MM-DD")
        }

        if (note) {
            body.note = note;
        }

        const callback = (resp) => {
            onClose();
            getData()
        };

        const errorCallback = (err) => {
            setMessage({ type: false, text: err });
        };

        REQUESTS.INVOICES.ADD(body, callback, errorCallback)
    }

    const getPackages = (value) => {
        const query = {
            limit: 10
        };

        if (value && value.length > 0) {
            query["search"] = {};

            query["search"]["name"] = value;

            query.search = JSON.stringify(query.search)
        }


        const callback = (resp) => {
            const packages = resp.rows?.map((pack) => {
                return {
                    label: pack.name,
                    value: pack.id,
                    price: pack.price
                }
            })
            setPackages(packages);

            if (products.length == 0) {
                setProducts((prevState) => {
                    return [
                        ...prevState,
                        {
                            package_id: packages?.[0]?.value,
                            amount: packages?.[0]?.price,
                            count: 1,
                            detailes: '',
                            index: 1
                        }
                    ]
                })
            }
        };

        REQUESTS.USER_PACKEGES.GET(query, callback)
    }

    const getUsers = (value) => {
        const query = {
            limit: 10
        };

        if (value && value.length > 0) {
            query["search"] = {};

            query["search"] = {
                "first_name": value,
                "last_name": value,
                "email": value
            }

            query.search = JSON.stringify(query.search)
        }

        const callback = (resp) => {
            const users = resp.rows?.map((user) => {
                return {
                    label: `${user.first_name} ${user.last_name}`,
                    value: user.id,
                }
            })
            setUsers(users);
        };

        REQUESTS.USERS.GET(query, callback)
    }

    const debouncedSearch = debounce((type, value) => {
        if (type === "user") {
            getUsers(value);
        } else {
            getPackages(value);
        }
    }, 300);

    const addProduct = () => {
        setProducts((prevState) => {
            return [
                ...prevState,
                {
                    package_id: packages?.[0]?.value,
                    amount: packages?.[0]?.price,
                    count: 1,
                    detailes: '',
                    index: prevState.length + 1
                }
            ]
        })
    }

    const removeProduct = (prod) => {
        let updatedArr = products.filter((product) => product.index !== prod.index);
        setProducts(updatedArr);
    }

    const onChangeProduct = (product, value, name) => {
        let updatedArr = products.map((prod) => {
            if (prod.index == product.index) {
                if (name === 'package_id') {
                    let pack = packages.find((pack) => pack.value == value);
                    prod.amount = pack.price;
                }
                prod[name] = value;
            }
            return prod;
        })

        setProducts(updatedArr);
    }

    useEffect(() => {
        if (open) {
            getUsers();
            getPackages()
        } else {
            form.resetFields()
            setProducts([]);
        }
    }, [open]);

    return (
        <Drawer
            open={open}
            onClose={() => {
                form.resetFields();
                onClose();
                setMessage({ type: false, text: "" });
            }}
            title="Create Invoice"
            width={resize > 700 ? 700 : "95%"}
            destroyOnClose={true}
        >
            <Form
                form={form}
                onFinish={onFinish}
                layout='vertical'
            >
                <Row gutter={[8, 8]} wrap={true}>
                    <Col span={8} >
                        <Form.Item
                            name="title"
                            label="Title"
                            rules={[{
                                required: true,
                                message: "Please enter title!"
                            }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8} >
                        <Form.Item
                            label="User"
                            name="user_id"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select user!"
                                }
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear
                                onSearch={(value) => debouncedSearch("user", value)}
                                options={users}
                                optionFilterProp="label"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8} >
                        <Form.Item
                            name="send_date"
                            label="Send Date"
                        >
                            <DatePicker
                                format="YYYY-MM-DD"
                                disabledDate={(current) => {
                                    return current && current < dayjs().startOf('day');
                                }}
                                style={{ width: "100%" }}
                            />

                        </Form.Item>
                    </Col>
                </Row>

                {
                    products.map((product, index) => {
                        return < Product
                            key={index}
                            packages={packages}
                            product={product}
                            onChangeProduct={onChangeProduct}
                            removeProduct={removeProduct}
                            onSearch={debouncedSearch}
                        />
                    })
                }
                <Row justify="end">
                    <Button type='primary' onClick={addProduct}><PlusOutlined />Product</Button>
                </Row>

                <Form.Item
                    name='note'
                    label="Note"
                    rules={[
                        {
                            max: 255,
                            message: "Note can't be more than 255 characters!"
                        }
                    ]}
                >
                    <Input.TextArea style={{ resize: "none" }} />
                </Form.Item>

                <ErrorMessage message={message} />

                <Row justify='end'>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">Create Invoice</Button>
                    </Form.Item>
                </Row>
            </Form>
        </Drawer>
    )
}
