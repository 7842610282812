const CONSTANTS = {
    JWT: "JWT",
    REMEMBER_ME: "REMEMBER_ME",

    API_HOST: window.API_HOST || "https://api.allnetcom.net",

    APP_COLOR: "black",
    LINK_COLOR: "white",
};

export default CONSTANTS;
