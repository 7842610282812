import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { Menu, Layout } from "antd";
import {
  DashboardOutlined,
  DesktopOutlined,
  HistoryOutlined,
  LockOutlined,
  ShoppingOutlined,
  SettingOutlined,
  AliwangwangOutlined,
  MailOutlined,
  WechatOutlined,
  ContainerOutlined,
  UsergroupDeleteOutlined,
  FileExcelOutlined,
  BookOutlined,
  UserAddOutlined,
  ScheduleOutlined, CalendarOutlined
} from "@ant-design/icons";

import REQUESTS from "../../api/requests";

import TEXT from "../../config/text";
import ICONS from "../../config/icons";

import { selectTranslation } from "../../features/Translation/TranslationSlice";
import { selectMeesageCount } from "../../features/chat/chatSlise";
import { selectwidgets } from "../../features/widgets/selector";

import { selectappInfo } from "../../features/appInfoSlice/AppInfoSlice";
import { selectUser } from "../../features/DataUserProfile/DataUserProfileSlise";

import classes from "../style/sidbar.module.scss";
import "../../index.scss";

const VERSION = require('../../../package.json').version

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

function Sidbar({ collapsed, setOpen }) {
  const location = useLocation();
  const navigate = useNavigate();

  const [apiVersion, setApiVersion] = useState("");

  const [current, setCurrent] = useState(location.pathname);

  const translation = useSelector(selectTranslation);
  const meesageCount = useSelector(selectMeesageCount);
  const widgets = useSelector(selectwidgets);
  const appInfo = useSelector(selectappInfo);
  const userInfo = useSelector(selectUser);

  const { sidebar_background, logo, sidebar_logo_size } = appInfo;

  const [menuItems, setMenuItems] = useState([]);

  const getApiVersion = () => {
    REQUESTS.GET_API_VERSION((data) => {
      setApiVersion(data)
    });
  };

  useEffect(() => {
    getApiVersion();
  }, []);


  useEffect(() => {
    const permissions = userInfo.admin_permissions;

    if (userInfo && permissions) {

      const hasPermission = (group, name) => {

        if (permissions[group]) {
          return permissions[group][name]
        }

        return false
      }

      let menuList = [
        hasPermission("dashboard", "Show dashboard page") && getItem(
          <Link className={classes["menu_item_link"]} to="./">
            {translation["Dashboard"] || TEXT["Dashboard"]}
          </Link>,
          "/DashboardPage",
          <DashboardOutlined />
        ),
        hasPermission("users", "Show users page") && getItem(
          <Link className={classes["menu_item_link"]} to={"./users"}>
            {translation["Users"] || TEXT["Users"]}
          </Link>,
          "/DashboardPage/users",
          <UsergroupDeleteOutlined />
        ),
        hasPermission("invoices", "Show invoice page") && getItem(
          <Link className={classes["menu_item_link"]} to={"invoices"}>
            Invoices
          </Link>,
          "/DashboardPage/invoices",
          <ScheduleOutlined />
        ),
        hasPermission("devices", "Show devices page") && getItem(
          <Link className={classes["menu_item_link"]} to={"./deviceManager"}>
            {translation["Devices"] || TEXT["Devices"]}
          </Link>,
          "/DashboardPage/deviceManager",
          <DesktopOutlined />
        ),
        hasPermission("payments", "Show payments page") && getItem(
          <Link className={classes["menu_item_link"]} to={"./paymentHistory"}>
            {translation["Payments"] || TEXT["Payments"]}
          </Link>,
          "/DashboardPage/paymentHistory",
          <HistoryOutlined />
        ),
        hasPermission("pricing", "Show pricing page") && getItem(
          <Link className={classes["menu_item_link"]} to={"./userPackages"}>
            {translation["Pricing"] || TEXT["Pricing"]}
          </Link>,
          "/DashboardPage/userPackages",
          <ShoppingOutlined />
        ),
        hasPermission("blacklist", "Show blacklist page") && getItem(
          <Link className={classes["menu_item_link"]} to={"./blacklist"}>
            {translation["Blacklist"] || TEXT["Blacklist"]}
          </Link>,
          "/DashboardPage/blacklist",
          <LockOutlined />
        ),
        hasPermission("reseller", "Show reseller/referral page") && getItem(
          <div className="label-icon-container">
            <Link className={classes["menu_item_link"]} to={"./referral"}>
              {translation["Referral"] || TEXT["Referral"]}
            </Link>
            <div> {ICONS.PRO}</div>
          </div>,
          "/DashboardPage/referral",
          ICONS.REFERRAL,
          (hasPermission("referral", "Withdraw request") ||
            hasPermission("referral", "Earnings") ||
            hasPermission("referral", "Links") ||
            hasPermission("referral", "Statuses")) && [
            hasPermission("referral", "Withdraw request") && getItem(
              <Link
                className={classes["menu_item_link"]}
                to={
                  widgets?.referral?.enabled === "true"
                    ? "/DashboardPage/referralwithdraw"
                    : "/DashboardPage/referral"
                }
              >
                {translation["Withdraw requests"] || TEXT["Withdraw requests"]}
              </Link>,
              "/DashboardPage/referralwithdraw"
            ),
            hasPermission("referral", "Earnings") && getItem(
              <Link
                className={classes["menu_item_link"]}
                to={
                  widgets?.referral?.enabled === "true"
                    ? "./referralEarnings"
                    : "/DashboardPage/referral"
                }
              >
                {translation["Earnings"] || TEXT["Earnings"]}
              </Link>,
              "/DashboardPage/referralEarnings"
            ),
            hasPermission("referral", "Links") && getItem(
              <Link
                className={classes["menu_item_link"]}
                to={
                  widgets?.referral?.enabled === "true"
                    ? "./referralLinks"
                    : "/DashboardPage/referral"
                }
              >
                {translation["Links"] || TEXT["Links"]}
              </Link>,
              "/DashboardPage/referralLinks"
            ),
            hasPermission("referral", "Statuses") && getItem(
              <Link
                className={classes["menu_item_link"]}
                to={
                  widgets?.referral?.enabled === "true"
                    ? "./referralStatuses"
                    : "/DashboardPage/referral"
                }
              >
                {translation["Statuses"] || TEXT["Statuses"]}
              </Link>,
              "/DashboardPage/referralStatuses"
            ),
          ]
        ),
        hasPermission("reseller", "Show reseller/referral page") && getItem(
          <div className="label-icon-container">
            <Link className={classes["menu_item_link"]} to={"./reseller"}>
              {translation["Reseller"] || TEXT["Reseller"]}
            </Link>
            <div> {ICONS.PRO}</div>
          </div>,
          "/DashboardPage/reseller",
          ICONS.RESELLER,
          (
            hasPermission("reseller", "Show credit packages") ||
            hasPermission("reseller", "Show credit history")
          ) &&
          [
            hasPermission("reseller", "Show credit packages") && getItem(
              <Link
                className={classes["menu_item_link"]}
                to={
                  widgets?.reseller?.enabled === "true"
                    ? "./referralpackages"
                    : "/DashboardPage/reseller"
                }
              >
                Credit packages
              </Link>,
              "/DashboardPage/referralpackages"
            ),
            hasPermission("reseller", "Show credit history") && getItem(
              <Link
                className={classes["menu_item_link"]}
                to={
                  widgets?.reseller?.enabled === "true"
                    ? "./referralactivation"
                    : "/DashboardPage/reseller"
                }
              >
                Credit History
              </Link>,
              "/DashboardPage/referralactivation"
            ),
          ]
        ),
        hasPermission("news", "Show news page") && getItem(
          <div className="label-icon-container">
            <div>
              <Link className={classes["menu_item_link"]} to={"./newsPage"}>
                {translation["News"] || TEXT["News"]}
              </Link>
            </div>
            <div>{ICONS.PRO}</div>
          </div>,
          "/DashboardPage/newsPage",
          <AliwangwangOutlined />
        ),
        hasPermission("chat", "Show chat page") && getItem(
          <div className="label-icon-container">
            <Link className={classes["menu_item_link"]} to={"./chat"}>
              <div className={classes["messagesCount_container"]}>
                {translation["Chat"] || TEXT["Chat"]}{" "}
                {meesageCount ? (
                  <div className={classes["messagesCount"]}>{meesageCount}</div>
                ) : (
                  ""
                )}
              </div>
            </Link>
            <div className="pro-icon">{ICONS.PRO}</div>
          </div>,
          "/DashboardPage/chat",
          <WechatOutlined />
        ),
        hasPermission("support", "Show support page") && getItem(
          <div className="label-icon-container">
            <Link className={classes["menu_item_link"]} to={"./support"}>
              {translation["Support"] || TEXT["Support"]}
            </Link>
            <div>{ICONS.PRO}</div>
          </div>,
          "/DashboardPage/support",
          <MailOutlined />
        ),
        hasPermission("gift codes", "Show gift codes page") && getItem(
          <div className="label-icon-container">
            <Link className={classes["menu_item_link"]} to={"./GIFTCode"}>
              {translation["Gift Codes"] || TEXT["Gift Codes"]}
            </Link>
            <div>{ICONS.PRO}</div>
          </div>,
          "/DashboardPage/coupons",
          <ContainerOutlined />
        ),
        hasPermission("notification", "Show notification page") && getItem(
          <div className="label-icon-container">
            <Link className={classes["menu_item_link"]} to={"notification"}>
              {translation["Notification"] || TEXT["Notification"]}
            </Link>
            <div>{ICONS.PRO}</div>
          </div>,
          "/DashboardPage/notification",
          <span>
            {ICONS.NOTIFICATION}
          </span>
        ),
        hasPermission("manage categories", "Show manage categories page") && getItem(
          <div className="label-icon-container">
            <Link className={classes["menu_item_link"]} to={"manage-categories"} style={{ fontSize: 13 }}>
              {translation["Manage categories"] || TEXT["Manage categories"]}
            </Link>
            <div>{ICONS.PRO}</div>
          </div>,
          "/DashboardPage/manage-categories",
          <span>
            {ICONS.MANAGE_CATEGORIES}
          </span>
        ),
        (hasPermission("xui one", "Show lines") ||
          hasPermission("xui one", "Show bouquets") ||
          hasPermission("xui one", "Show settings")) && getItem(
            "XUI.one",
            "/DashboardPage/xui.one",
            <FileExcelOutlined />,
            [
              hasPermission("xui one", "Show lines") && {
                label: <Link className={classes["menu_item_link"]} to={"xui.one-lines"}>
                  Lines
                </Link>,
                key: "/DashboardPage/xui.one-lines",
                icon: <DesktopOutlined />,
              },
              hasPermission("xui one", "Show bouquets") && {
                label: <Link className={classes["menu_item_link"]} to={"xui.one-bouquets"}>
                  Bouquets
                </Link>,
                key: "/DashboardPage/xui.one-bouquets",
                icon: <BookOutlined />,
              },
              hasPermission("xui one", "Show settings") && {
                label: <Link className={classes["menu_item_link"]} to={"xui.one-settings"}>
                  Settings
                </Link>,
                key: "/DashboardPage/xui.one-settings",
                icon: <SettingOutlined />,
              },
            ]
          ),
        hasPermission("admins", "Show admins page") && getItem(
          <Link className={classes["menu_item_link"]} to={"./create-admin"}>
            Admins
          </Link>,
          "/DashboardPage/create-admin",
          <UserAddOutlined />
        ),
        hasPermission("calendar", "Show calendar page") &&
        getItem(
          <Link className={classes["menu_item_link"]} to={"./calendar_reminders"}>
            Calendar
          </Link>,
          "/DashboardPage/calendar",
          <CalendarOutlined />
        ),
        hasPermission("tasks", "Show tasks page") && getItem(
          <Link className={classes["menu_item_link"]} to={"./tasks"}>
            Tasks
          </Link>,
          "/DashboardPage/tasks",
          <span>{ICONS.TASKS}</span>
        ),
        (hasPermission("settings", "Show smtp") ||
          hasPermission("settings", "Show application/branding") ||
          hasPermission("settings", "Show payment settings") ||
          hasPermission("settings", "Show languages") ||
          hasPermission("settings", "Show google ima") ||
          hasPermission("settings", "Show google recaptcha") ||
          hasPermission("settings", "Show geo restriction") ||
          hasPermission("settings", "Update reseller translation")) &&
        getItem(
          <Link className={classes["menu_item_link"]} to={"./settings"}>
            {translation["Settings"] || TEXT["Settings"]}
          </Link>,
          "/DashboardPage/settings",
          <SettingOutlined />
        ),
      ]

      setMenuItems(menuList.filter((item) => item))
    }

  }, [userInfo, widgets])

  useEffect(() => {
    if (menuItems && menuItems[0] && menuItems[0]["key"]) {
      navigate(menuItems[0]["key"])
      setCurrent(menuItems[0]["key"]);
    }
  }, [menuItems])

  return (
    <Layout.Sider
      style={{
        height: "100vh",
        overflowY: "auto",
        overflowX: "hidden",
        backgroundColor: sidebar_background || "#1a1a1a",
      }}
      className="sidebar"
      trigger={null}
      collapsible
      collapsed={collapsed}
      width={220}
    >
      <div className={classes["logo-wrapper"]}>
        <div className={classes["logo"]}>
          <img
            src={logo || "assets/img/sidbarlogo.png"}
            onError={(e) => {
              e.target.src = "assets/img/sidbarlogo.png";
            }}
            style={{ width: sidebar_logo_size || "100px" }}
            alt="logo"
          />
        </div>
      </div>
      <div className={classes["sidebar"]}>
        <Menu
          selectedKeys={[current]}
          mode="inline"
          inlineCollapsed={collapsed}
          items={menuItems}
          style={{
            paddingTop: 0,
            fontSize: 15,
            minHeight: "78vh",
            backgroundColor: "transparent",
          }}
          onClick={(e) => {
            setCurrent(e.key);
            if (setOpen) {
              setOpen(false);
            }
          }}
        />

        <div
          className={classes["sidebar-footer-section"]}
          style={{
            fontSize: 10,
            marginLeft: 10,
          }}
        >
          <span>API {apiVersion}</span>
          <p>Admin v{VERSION}</p>
        </div>
      </div>
    </Layout.Sider>
  );
}

export default Sidbar;
