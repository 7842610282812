import REQUESTS from "../../api/requests";
import { useEffect, useState, useContext } from "react";
import {
  Table,
  Button,
  Input,
  Modal,
  DatePicker,
  Switch,
  Popover,
  Empty,
} from "antd";
import { emptyContext } from "../../context_empty/context_empty";
import ActionButton from "../../components/ActionButton";
import classes from "./index.module.scss";
import { useSelector } from "react-redux";
import SupportDrawer from "./components/SupportDrawer";
import { selectTranslation } from "../../features/Translation/TranslationSlice";
import { selectwidgets } from "../../features/widgets/selector";

import showPropsConfirm from "../../components/showPropsConfirm";
import getMyDate from "../../components/getMyDate";
import TEXT from "../../config/text";
import {
  SearchOutlined,
  CalendarOutlined,
  EyeOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
// import SupportDrawer from "./components/SupportDrawer";
import InitialParams from "../../components/InitialParams";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { selectUser } from "../../features/DataUserProfile/DataUserProfileSlise";
const { RangePicker } = DatePicker;
const { confirm } = Modal;

function Support() {
  const [searchParams, setsearchParams] = useSearchParams();
  const [total, setTotal] = useState();
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [editSupport, setEditSupport] = useState(null);
  const [date, setDate] = useState("");
  const [support, setSupport] = useState([]);
  const [row, setRow] = useState("answered");
  const [sort, setSort] = useState(["id", "DESC"]);
  const [bull, setBull] = useState(false);
  const [search, setSearch] = useState({
    comment: "",
  });

  const { admin_permissions } = useSelector(selectUser);

  InitialParams(setLimit, setCurrentPage, setSearch, setSort);

  const navigate = useNavigate();
  const goToPosts = (params) =>
    navigate({
      search: `?${createSearchParams(params)}`,
    });

  const translation = useSelector(selectTranslation);
  const widgets = useSelector(selectwidgets);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
      return (
        <Input
          allowClear
          autoFocus
          placeholder="Type text here"
          value={search[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
          }}
        />
      );
    },
    filterIcon: () => {
      return (
        <>
          <SearchOutlined />
        </>
      );
    },
  });

  const viewed = (id) => {
    const callback = (data) => {
      getSupport();
    };

    const errorCallback = (data) => { };

    REQUESTS.TICKETS.WIEWED(id, callback, errorCallback);
  };

  const deleteTable = (id) => {
    const callback = (data) => {
      getSupport();
    };

    const errorCallback = (data) => { };

    REQUESTS.TICKETS.DELETE(id, callback, errorCallback);
  };

  const columns = [
    {
      title: "#",
      width: "60px",
      dataIndex: "id",
      align: "center",
      render: (text, record, index) => {
        if (currentPage === 1) {
          return index + 1;
        } else {
          return limit * (currentPage - 1) + index + 1;
        }
      },
    },

    {
      title: `${translation["Title"] || TEXT["Title"]}`,
      dataIndex: "title",
      width: "50px",
      key: "title",
      align: "center",
      render: (record, el, index) => {
        if (record) {
          const content = record;
          return (
            <Popover content={content}>
              <p
                className={
                  classes[
                  el.status === "new"
                    ? "new_message_status"
                    : "viewed_message_status"
                  ]
                }
                style={{ width: "150px" }}
              >
                {record}
              </p>
            </Popover>
          );
        }
      },
    },

    {
      title: `${translation["Message"] || TEXT["Message"]}`,
      dataIndex: "message",
      with: "100px",
      align: "center",
      render: (record, el, index) => {
        if (record) {
          const content = record;
          return (
            <Popover content={content}>
              <div
                className={
                  classes[
                  el.status === "new"
                    ? "new_message_status"
                    : "viewed_message_status"
                  ]
                }
                style={{ width: "300px" }}
              >
                {record}
              </div>
            </Popover>
          );
        }
      },
    },

    {
      title: `${translation["Email"] || TEXT["Email"]}`,
      dataIndex: "email",
      key: "email",
      align: "center",
      sorter: true,
      ...getColumnSearchProps(),
      render: (record, el, index) => {
        if (record) {
          return (
            <p
              className={
                classes[
                el.status === "new"
                  ? "new_message_status"
                  : "viewed_message_status"
                ]
              }
            >
              {record}
            </p>
          );
        }
      },
    },

    {
      title: ` ${translation["Date"] || TEXT["Date"]}`,
      align: "center",
      dataIndex: "createdAt",
      sorter: true,
      render: (record, el, index) => {
        return (
          <p
            className={
              classes[
              el.status === "new"
                ? "new_message_status"
                : "viewed_message_status"
              ]
            }
          >
            {getMyDate(record)}
          </p>
        );
      },
      filterDropdown: ({ }) => (
        <div
          style={{
            padding: 8,
          }}
        >
          <RangePicker
            size="small"
            onChange={(e, dateString) => setDate(dateString)}
            renderExtraFooter={() => "extra footer"}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <CalendarOutlined
          style={{
            color: JSON.parse(searchParams.get("between"))?.createdAt
              ? "#1890ff"
              : undefined,
          }}
        />
      ),
    },

    {
      title: `${translation["Status"] || TEXT["Status"]}`,
      dataIndex: "message",
      with: "80px",
      align: "center",
      sorter: true,
      render: (record, element, index) => {
        if (element.status === "new") {
          return (
            <div className={classes["message_container"]}>
              <div className={classes["new_message_indicator"]}></div>
              <p className={classes["new_message_status"]}>New</p>
            </div>
          );
        } else if (element.status === "viewed") {
          return (
            <div className={classes["message_container"]}>
              <div className={classes["viewed_message_indicator"]}></div>
              <p className={classes["viewed_message_status"]}>Viewed</p>
            </div>
          );
        } else if (element.status === "answered") {
          return (
            <div className={classes["message_container"]}>
              <div className={classes["answered_message_indicator"]}></div>
              <p className={classes["answered_message_status"]}>Answered</p>
            </div>
          );
        }
      },
    },

    {
      title: `${translation["View"] || TEXT["View"]}`,
      width: "80px",
      dataIndex: "action",
      align: "center",
      render: (text, record, index) => (
        <ActionButton
          handleMenuClick={(e) => {
            setEditSupport(record);
            setVisible(true);
            if (record.status === "new") {
              viewed(record.id);
            }
          }}
          disabled={admin_permissions && admin_permissions["support"] && !admin_permissions["support"]["Viewed"]}
          text={""}
          index={index}
          icon={<EyeOutlined />}
        />
      ),
    },

    {
      title: `${translation["Delete"] || TEXT["Delete"]}`,
      width: "50px",
      align: "center",
      render: (text, record, index) => (
        <ActionButton
          handleMenuClick={(e) =>
            showPropsConfirm(
              record.id,
              deleteTable,
              loading,
              `${translation["DELETE"] || TEXT["DELETE"]}`,
              "#bf4342",
              `${translation["Are you sure you want to delete?"] ||
              TEXT["Are you sure you want to delete?"]
              }`,
              `${translation["CANCEL"] || TEXT["CANCEL"]}`
            )
          }
          text={""}
          index={index}
          icon={<DeleteOutlined />}
          disabled={admin_permissions && admin_permissions["support"] && !admin_permissions["support"]["Delete"]}
        />
      ),
    },
  ];

  const getSupport = () => {
    setLoading(true);
    const query = {
      page: currentPage,
      limit,
      pagination: 1,
      sort: JSON.stringify(sort),
      search: {},
    };

    if (search.email) {
      query.search["email"] = search.email[0];
    }

    if (query.search) {
      query.search = JSON.stringify(query.search);
    }

    if (date && date[0]) {
      let to = new Date(date[1]);
      to.setDate(to.getDate() + 1);
      query.between = JSON.stringify({
        createdAt: {
          from: new Date(date[0]),
          to: new Date(to),
        },
      });
    }

    goToPosts(query);

    function callback(data) {
      setLoading(false);

      setTotal(data.count);
      // setLimit(data.limit);
      if (data.rows.length === 0 && currentPage !== 1) {
        setCurrentPage((current) => current - 1);
      } else {
        setCurrentPage(data.currentPage);
      }

      for (let i = 0; i < data.rows.length; i++) {
        if (data.rows[i].answered === false && data.rows[i].viewed === false) {
          data.rows[i].status = "new";
        } else if (
          data.rows[i].answered === false &&
          data.rows[i].viewed === true
        ) {
          data.rows[i].status = "viewed";
        } else if (
          data.rows[i].answered === true &&
          data.rows[i].viewed === true
        ) {
          data.rows[i].status = "answered";
        }
      }

      setSupport(data.rows);
    }

    function errorCallback(err) {
      setLoading(false);
    }
    REQUESTS.TICKETS.GET(query, callback, errorCallback);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
    setSearch(filters);
    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  const onClickAdd = () => {
    setVisible(true);
  };

  const closeDrawer = () => {
    setVisible(false);
    setEditSupport(null);
  };

  useEffect(() => {
    let timout = setTimeout(() => {
      getSupport();
    }, 500);
    return () => {
      clearTimeout(timout);
    };
  }, [search, currentPage, limit, sort, date]);

  useEffect(() => {
    setInterval(() => {
      setBull((current) => {
        const res = !current;

        return res;
      });
    }, 60000);
  }, []);

  useEffect(() => {
    getSupport();
  }, [bull]);

  const followAddElement = {
    setCurrentPage: setCurrentPage,
    setSearch: setSearch,
    setSort: setSort,
    setLimit: setLimit,
    setTotal: setTotal,
    setDate: setDate,
  };

  return (
    <div className={classes["support_list_table"]}>
      {((widgets && !widgets.support) ||
        (widgets &&
          widgets.support &&
          widgets.support.enabled &&
          widgets.support.enabled === "false")) && (
          <>
            <div className="pro-widget-warning-text">
              To use this feature you need to upgrade to Pro Version
              <br />
              Please contact us for more details.
            </div>
            <div className="pro-widget-tab-layer"></div>
          </>
        )}
      <div className={classes["support_list_title"]}>
        <p className={classes["support_title-text"]}>
          {/* <ShoppingOutlined /> */}
          {translation["Support"] || TEXT["Support"]}
        </p>
      </div>

      <Table
        loading={loading}
        rowKey="id"
        columns={columns}
        dataSource={support}
        rowClassName={classes["support_row"]}
        onChange={handleTableChange}
        onRow={(record, rowIndex) => {
          return {
            onClick: (e) => {
              setRow(record);
            },
          };
        }}
        pagination={{
          position: ["bottomCenter"],
          current: currentPage,
          total: total,
          pageSize: limit,
          showSizeChanger: true,
          // defaultPageSize: 1,
        }}
        scroll={{ x: "max-content" }}
        size="small"
        locale={{
          emptyText: (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
          ),
        }}
      />

      <SupportDrawer
        visible={visible}
        onClose={closeDrawer}
        current={editSupport}
        support={row}
        getData={getSupport}
        translation={translation}
        followAddElement={followAddElement}
      />
    </div>
  );
}

export default Support;
