import REQUESTS from "../../../api/requests";
import { useEffect, useState, useContext } from "react";
import { Table, Button, Input, Modal, notification } from "antd";
import TableButtons from "../../../components/TableButtons";
import classes from "../style/statusList.module.scss";
import TEXT from "../../../config/text";
import { useSelector } from "react-redux";
import { selectTranslation } from "../../../features/Translation/TranslationSlice";
import { emptyContext } from "../../../context_empty/context_empty";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import showPropsConfirm from "../../../components/showPropsConfirm";
import StausListDrawer from "./StatusDrawer";
import InitialParams from "../../../components/InitialParams";
import { createSearchParams, useNavigate } from "react-router-dom";
import { selectUser } from "../../../features/DataUserProfile/DataUserProfileSlise";
const { confirm } = Modal;

function StatusList() {
  const [total, setTotal] = useState();
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [ediPackage, setEdiPackage] = useState(null);
  const [statusList, setStatusList] = useState([]);
  const [sort, setSort] = useState(["id", "DESC"]);
  const [search, setSearch] = useState({
    comment: "",
  });

  const { admin_permissions } = useSelector(selectUser)

  InitialParams(setLimit, setCurrentPage, setSearch, setSort);

  const navigate = useNavigate();
  const goToPosts = (params) =>
    navigate({
      search: `?${createSearchParams(params)}`,
    });

  const translation = useSelector(selectTranslation);
  const arr = [3, 5, 10, 2, 6, 1, 8, 33, 41, 76];

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
      return (
        <Input
          allowClear
          autoFocus
          placeholder="Type text here"
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
          }}
        />
      );
    },
    filterIcon: () => {
      return (
        <>
          <SearchOutlined />
        </>
      );
    },
  });

  const deleteTable = (id) => {
    const callback = (data) => {
      getStatusList();
    };

    const errorCallback = (data) => {
      openNotification(data);

    };

    REQUESTS.STATUS.DELETE(id, callback, errorCallback);
  };

  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "delete":
        showPropsConfirm(
          item.id,
          deleteTable,
          loading,
          `${translation["DELETE"] || TEXT["DELETE"]}`,
          "#bf4342",
          `${translation["Are you sure you want to delete?"] ||
          TEXT["Are you sure you want to delete?"]
          }`,
          `${translation["CANCEL"] || TEXT["CANCEL"]}`
        );
        break;
      case "edit":
        setEdiPackage(item);
        setVisible(true);
        break;

      default:
        break;
    }
  };

  const openNotification = (message) => {
    const args = {
      message: "error",
      description: message,
      duration: 5,
      style: {
        color: "red",
      },
    };
    notification.open(args);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: "60px",
      align: "center",
      render: (text, record, index) => {
        if (currentPage === 1) {
          return index + 1;
        } else {
          return limit * (currentPage - 1) + index + 1;
        }
      },
    },
    {
      title: `${translation["Name"] || TEXT["Name"]}`,
      dataIndex: "name",
      key: "name",
      align: "center",
      ...getColumnSearchProps("name"),
    },

    {
      title: `${translation["Earning"] || TEXT["Earning"]}`,
      dataIndex: "earning",
      key: "earning",
      align: "center",
      sorter: true,
    },
    {
      title: `${translation["Switch amount"] || TEXT["Switch amount"]}`,
      dataIndex: "switch_amount",
      sorter: true,
      align: "center",
    },

    {
      title: "",
      width: "50px",
      align: "center",
      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          buttons={admin_permissions && admin_permissions['referral'] && [
            {
              key: "edit",
              text: `${translation["Edit"] || TEXT["Edit"]}`,
              icon: <EditOutlined />,
              disabled: !admin_permissions['referral']["Update status"]
            },
            {
              key: "delete",
              text: `${translation["Delete"] || TEXT["Delete"]}`,
              icon: <DeleteOutlined />,
              disabled: !admin_permissions['referral']["Delete status"]
            },
          ]}
        />
      ),
    },
  ];

  const getStatusList = () => {
    setLoading(true);
    const query = {
      page: currentPage,
      limit,
      pagination: 1,
      sort: JSON.stringify(sort),
      search: {},
    };

    if (search.earning) {
      query.search["earning"] = search.earning[0];
    }

    if (search.name) {
      query.search["name"] = search.name[0];
    }

    if (query.search) {
      query.search = JSON.stringify(query.search);
    }

    goToPosts(query);

    REQUESTS.STATUS.GET(query, (data) => {
      setTotal(data.count);

      if (data.rows.length === 0 && currentPage !== 1) {
        setCurrentPage((current) => current - 1);
      } else {
        setCurrentPage(data.currentPage);
      }
      setLoading(false);
      setStatusList(data.rows);
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
    setSearch(filters);
    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  const onClickAdd = () => {
    setVisible(true);
  };

  const closeDrawer = () => {
    setVisible(false);
    setEdiPackage(null);
  };

  useEffect(() => {
    let timout = setTimeout(() => {
      getStatusList();
    }, 500);
    return () => {
      clearTimeout(timout);
    };
  }, [search, currentPage, limit, sort]);

  const followAddElement = {
    setCurrentPage: setCurrentPage,
    setSearch: setSearch,
    setSort: setSort,
    setCurrentPage: setCurrentPage,
    setLimit: setLimit,
    setTotal: setTotal,
  };

  return (
    <div className={classes["status_table"]}>
      <div className={classes["status_table_head"]}>
        <p className={classes["status_head_text"]}>
          {translation["Status"] || TEXT["Status"]}
        </p>
        {
          admin_permissions &&
          admin_permissions['referral']["Add status"] &&
          <Button
            className={classes["status_add"]}
            type="primary"
            icon={<PlusOutlined />}
            onClick={onClickAdd}
          />
        }
      </div>

      <Table
        loading={loading}
        rowKey="id"
        columns={columns}
        dataSource={statusList}
        onChange={handleTableChange}
        pagination={{
          position: ["bottomCenter"],
          current: currentPage,
          total: total,
          pageSize: limit,
          showSizeChanger: true,
          // defaultPageSize: 1,
        }}
        scroll={{ x: "max-content" }}
        size="small"
      />

      <StausListDrawer
        visible={visible}
        onClose={closeDrawer}
        current={ediPackage}
        getData={getStatusList}
        translation={translation}
        followAddElement={followAddElement}
      />
    </div>
  );
}

export default StatusList;
