import REQUESTS from "../../../api/requests";
import { useState, useEffect } from "react";
import classes from "../style/aplicationSettings.module.scss";
import { Button, Form, Input, notification } from "antd";
import { useSelector } from "react-redux";
import { selectTranslation } from "../../../features/Translation/TranslationSlice";
import TEXT from "../../../config/text";

import { useNavigate, createSearchParams } from "react-router-dom";

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import axios from "axios";
import { selectUser } from "../../../features/DataUserProfile/DataUserProfileSlise";

const ApplicationSettigs = () => {
  const translation = useSelector(selectTranslation);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [application, setApplication] = useState(null);
  const [deafultCountry, setDefaultCountry] = useState("");
  const [phone, setPhone] = useState()
  const { admin_permissions } = useSelector(selectUser)

  const [form] = Form.useForm();

  const goToPosts = (params) =>
    navigate({
      search: `?${createSearchParams(params)}`,
    });

  const openNotification = (message) => {
    const args = {
      message: `${translation["Application"] || TEXT["Application"]}`,
      description: message,
      duration: 5,
    };
    notification.open(args);
  };

  const getApplication = () => {
    const collback = (data) => {
      setApplication(data);
    };

    REQUESTS.APP_INFO.GET(collback);
  };

  const onFinish = (values) => {

    setLoading(true);
    const formData = new FormData();

    formData.append("app_name", values.application_name);
    formData.append("phone", values.phone);
    formData.append("id", application?.id);
    formData.append("email", values.email);
    formData.append("free_trial_duration", values.free_trial_duration);
    formData.append("location_name", values.location_name);
    formData.append("location_url", values.location_url);

    function callback(data) {
      setLoading(false);
      getApplication();

      openNotification("Application update");
    }

    function errorCallback(err) {
      setLoading(false);

      if (typeof err == "string") {
        openNotification(err);
      }
    }

    REQUESTS.APP_INFO.UPDATE(formData, callback, errorCallback);
  };

  useEffect(() => {
    form.setFields([
      {
        name: "application_name",
        value: application?.app_name,
      },
      {
        name: "email",
        value: application?.email,
      },

      {
        name: "paypal",
        value: application?.paypal_client_id,
      },

      {
        name: "phone",
        value: application?.phone,
      },

      {
        name: "free_trial_duration",
        value: application?.free_trial_duration,
      },
      {
        name: "location_name",
        value: application?.location_name,
      },
      {
        name: "location_url",
        value: application?.location_url,
      },
    ]);
  }, [application]);

  useEffect(() => {
    getApplication();

    goToPosts({ page: "applicationSettigs" });

    axios.get('https://ipapi.co/json/').then(response => {
      setDefaultCountry(response.data.country)
    })

  }, []);

  return (
    <div className={classes["app_info"]}>
      <Form form={form} name="profile" layout="vertical" onFinish={onFinish}>
        <div className={classes["app_info_form"]}>
          <div className={classes["app_info_inputs"]}>
            <Form.Item
              name="application_name"
              label={
                translation["Application name"] || TEXT["Application name"]
              }
              rules={[
                {
                  required: true,
                  message: "application name",
                },
              ]}
            >
              <Input className={classes["ant_input"]} />
            </Form.Item>
            <Form.Item
              name="free_trial_duration"
              label={
                translation["Free trial duration"] ||
                TEXT["Free trial duration"]
              }
              rules={[
                {
                  required: true,
                  message: "Please input free trial duration",
                },
              ]}
            >
              <Input className={classes["ant_input"]} type="number" />
            </Form.Item>
            <Form.Item
              name="phone"
              label={
                translation["Support phone number"] ||
                TEXT["Support phone number"]
              }
              rules={[
                {
                  required: true,
                  message: "Please input phone",
                },
              ]}
            >
              <PhoneInput
                international
                value={phone}
                onChange={setPhone}
                defaultCountry={deafultCountry}
              />
            </Form.Item>
            <Form.Item
              name="email"
              label={
                translation["Support email address"] ||
                TEXT["Support email address"]
              }
              rules={[
                {
                  required: true,
                  message: "Please input address",
                },
              ]}
            >
              <Input className={classes["ant_input"]} />
            </Form.Item>
            <Form.Item
              name="location_name"
              label={translation["Location name"] || TEXT["Location name"]}
              rules={[
                {
                  required: true,
                  message: "Please input location name",
                },
              ]}
            >
              <Input className={classes["ant_input"]} />
            </Form.Item>
            <Form.Item
              name="location_url"
              label={translation["Location url"] || TEXT["Location url"]}
              rules={[
                {
                  required: true,
                  message: "Please input location url",
                },
              ]}
            >
              <Input className={classes["ant_input"]} />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                className={classes["aplication_button"]}
                loading={loading}
                onClick={() => form.submit()}
                disabled={admin_permissions && admin_permissions["settings"] &&
                  !admin_permissions["settings"]["Update application/branding"]}
              >
                {translation["Save"] || TEXT["Save"]}
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ApplicationSettigs;
