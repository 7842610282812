import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Route, Routes, Navigate } from "react-router-dom";

import { Helmet } from "react-helmet";

import { ConfigProvider } from "antd";

import { editAppInfo } from "./features/appInfoSlice/AppInfoSlice";
import { selectappInfo } from "./features/appInfoSlice/AppInfoSlice";
import { editTranslation } from "./features/Translation/TranslationSlice";
import { editPaymentSettings, editwidgets } from "./features/widgets/actions";
import { selectUser } from "./features/DataUserProfile/DataUserProfileSlise";

import { emptyContext } from "./context_empty/context_empty";

import REQUESTS from "./api/requests";

import DashboardPage from "./layout";

import CONSTANTS from "./config";

import Users from "./pages/users";
import Tasks from "./pages/tasks";
import NewsPage from "./pages/news";
import Support from "./pages/support";
import Coupons from "./pages/coupons";
import Statuses from "./pages/Statuses";
import Withdraw from "./pages/withdraw";
import Settings from "./pages/settings";
import Login from "./pages/login/index";
import Blacklist from "./pages/blacklist";
import Invoices from "./pages/invoices";
import PackagePage from "./pages/packages";
import Activation from "./pages/activation";
import Lines from "./pages/xui.one/Lines";
import CreateAdminPage from "./pages/admins";
import UserPackages from "./pages/userPackages";
import RealTimeChat from "./pages/realTimeChat";
import CalendarReminder from "./pages/calendar";
import Bouquets from "./pages/xui.one/Bouquets";
import Referral from "./pages/referral/Referral";
import Reseller from "./pages/reseller/Reseller";
import SettingsXUI from "./pages/xui.one/Settings";
import Dashboard from "./pages/dashboard/index";
import ReferralLinks from "./pages/referralLinks";
import PaymentHistory from "./pages/paymentHistory";
import ResetPasswordPage from "./pages/resetPassword";
import ReferralEarnings from "./pages/referralEarnings";
import DeviceManager from "./pages/deviceManager/index";
import PrivateRoute from "./pages/privateRoute/privateRoute";
import Notification from "./pages/notification/Notification";
import ManageCategories from "./pages/manageCategories/ManageCategories";

import "antd/dist/antd.variable.min.css";
import "./index.scss"

import { getToken } from "./utils/firebaseMessaging";

function App() {
  const dispatch = useDispatch();

  const appInfo = useSelector(selectappInfo);

  const [empty, setEmpty] = useState(true);

  const { admin_permissions } = useSelector(selectUser);

  const getTranslations = () => {
    function collback(data) {
      dispatch(editTranslation(data));
    }

    function errorCollback(data) { }

    REQUESTS.TEXT_TRANSLATION(collback, errorCollback, { id: 1 });
  };

  const getwidgets = () => {
    const collback = (data) => {
      dispatch(editwidgets(data));
    };
    REQUESTS.WIDGETS.GET(collback);
  };

  const getAppInfo = () => {
    // setLoading(true);
    const collback = (data) => {
      dispatch(editAppInfo(data));
      getwidgets();

      const root = document.documentElement;

      root.style.setProperty("--app-color", CONSTANTS.APP_COLOR);
      root.style.setProperty("--sidebar_item_color", data.sidebar_item_color);
      root.style.setProperty("--active-color", data.sidebar_item_active_color);
      root.style.setProperty("--active-background", data.sidebar_item_active_background);
      root.style.setProperty("--logo-size", data.sidebar_logo_size);
    };

    REQUESTS.APP_INFO.GET(collback);
  };

  ConfigProvider.config({
    theme: {
      primaryColor: CONSTANTS.APP_COLOR,
    },
  });

  const hasPermission = (group, name) => {
    if (admin_permissions && admin_permissions[group]) {
      return admin_permissions[group][name];
    }
    return false;
  }

  const getPaymentSettings = () => {
    REQUESTS.PAYMENT_SETTINGS((data) => {
      dispatch(editPaymentSettings(data));
    });
  };

  useEffect(() => {
    getPaymentSettings();
    getAppInfo();
    getTranslations();
    getToken()
  }, []);

  return (
    <ConfigProvider>
      <emptyContext.Provider value={[empty, setEmpty]}>
        <Helmet>
          <title>All Net</title>
          <link
            rel="icon"
            href={
              appInfo?.favicon || "/assets/images/icons/favicon-32x32.png"
            }
            type="image/x-icon"
          />
        </Helmet>

        <Routes>
          <Route path="/" element={<Navigate replace to="login" />} />

          <Route path="/login" element={<Login />} />

          <Route path={"/reset_password"} element={<ResetPasswordPage />} />

          <Route
            path="/DashboardPage"
            element={
              <PrivateRoute>
                <DashboardPage />
              </PrivateRoute>
            }
          >
            {hasPermission("dashboard", "Show dashboard page") && <Route index element={<Dashboard />} />}

            {hasPermission("users", "Show users page") && <Route path="users" element={<Users />} />}

            {hasPermission("devices", "Show devices page") && <Route path="deviceManager" element={<DeviceManager />} />}

            {hasPermission("reseller", "Show reseller/referral page") && <Route path="referral" element={<Referral />} />}

            {hasPermission("referral", "Withdraw request") && <Route path="referralwithdraw" element={<Withdraw />} />}

            {hasPermission("referral", "Statuses") && <Route path="referralStatuses" element={<Statuses />} />}

            {hasPermission("referral", "Earnings") && <Route path="referralEarnings" element={<ReferralEarnings />} />}

            {hasPermission("referral", "Links") && <Route path="referralLinks" element={<ReferralLinks />} />}

            {hasPermission("reseller", "Show credit packages") && <Route path="referralpackages" element={<PackagePage />} />}

            {hasPermission("reseller", "Show credit history") && <Route path="referralactivation" element={<Activation />} />}

            {hasPermission("reseller", "Show reseller/referral page") && <Route path="reseller" element={<Reseller />} />}

            {hasPermission("pricing", "Show pricing page") && <Route path="userPackages" element={<UserPackages />} />}

            {hasPermission("blacklist", "Show blacklist page") && <Route path="blacklist" element={<Blacklist />} />}

            {hasPermission("payments", "Show payments page") && <Route path="paymentHistory" element={<PaymentHistory />} />}

            {hasPermission("settings", "Show smtp") &&
              hasPermission("settings", "Show application/branding") &&
              hasPermission("settings", "Show payment settings") &&
              hasPermission("settings", "Show languages") &&
              hasPermission("settings", "Show google ima") &&
              hasPermission("settings", "Update reseller translation") &&
              hasPermission("settings", "Show google recaptcha") &&
              hasPermission("settings", "Show geo restriction") &&
              <Route path="settings" element={<Settings />} />
            }

            {hasPermission("news", "Show news page") && <Route path="newsPage" element={<NewsPage />} />}

            {hasPermission("support", "Show support page") && <Route path="support" element={<Support />} />}

            {hasPermission("chat", "Show chat page") && <Route path="chat" element={<RealTimeChat />} />}

            {hasPermission("gift codes", "Show gift codes page") && <Route path="GIFTCode" element={<Coupons />} />}

            {hasPermission("notification", "Show notification page") && <Route path="notification" element={<Notification />} />}

            {hasPermission("manage categories", "Show manage categories page") && <Route path="manage-categories" element={<ManageCategories />} />}

            {hasPermission("admins", "Show admins page") && <Route path="create-admin" element={<CreateAdminPage />} />}

            {hasPermission("xui one", "Show lines") && <Route path="xui.one-lines" element={<Lines />} />}

            {hasPermission("xui one", "Show bouquets") && <Route path="xui.one-bouquets" element={<Bouquets />} />}

            {hasPermission("xui one", "Show settings") && <Route path="xui.one-settings" element={<SettingsXUI />} />}

            {hasPermission("invoices", "Show invoice page") && <Route path="invoices" element={<Invoices />} />}

            {hasPermission("calendar", "Show calendar page") && <Route path="calendar_reminders" element={<CalendarReminder />} />}

            {hasPermission("tasks", "Show tasks page") && <Route path="tasks" element={<Tasks />} />}

          </Route>
          <Route path="*" element={<Navigate replace to="/DashboardPage" />} />
        </Routes>
      </emptyContext.Provider>
    </ConfigProvider >
  );
}

export default App;
