import { notification } from "antd";

import REQUESTS from "../api/requests";

const firebaseConfig = {
    apiKey: "AIzaSyBLCsDTrsx-6vxDK8ywkd09XlkTziNSS40",
    authDomain: "all-net-76f05.firebaseapp.com",
    projectId: "all-net-76f05",
    storageBucket: "all-net-76f05.firebasestorage.app",
    messagingSenderId: "661644666259",
    appId: "1:661644666259:web:86e9a028493595934cf466",
    measurementId: "G-040F2676JN"
};

const firebase = window.firebase

firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();

messaging.usePublicVapidKey("BJI1BiI35ckCQjX7SiD_AH8BjICv9_tEqilM1Pszp3f0qUFryK_Az3bC2i0vFSbvPx0_XX0X3LgfHBJZVtKlauk");

export function getToken() {
    messaging.getToken()
        .then((token) => {
            const tokenFromLocalStorage = localStorage.getItem('token_fcm');

            if (token) {
                if (!tokenFromLocalStorage || tokenFromLocalStorage != token) {
                    sendTokenToServer(token, tokenFromLocalStorage);
                }
            } else {
                console.log('No Instance ID token available. Request permission to generate one.');
            }
        })
        .catch((err) => {
            console.error(err);
        });
}

// getToken();

messaging.onTokenRefresh(getToken);

messaging.onMessage((payload) => {
    const { title, body } = payload?.notification || {};
    const { type } = payload?.data || {};

    console.log("onMessage payload : ", payload);

    const adminToken = localStorage.getItem("TOKEN");

    const redirect = type == "task" ? "/DashboardPage/tasks" : "/DashboardPage/calendar_reminders";

    window.location.href = adminToken ? "#" + redirect : `#/login?redirect=${redirect}`;

    notification.open({
        message: title,
        description: body,
    });
});

//send request to you'r application to add new user token for push notifications
function sendTokenToServer(token, old_token) {
    const body = {
        token,
    };

    if (old_token) {
        body.old_token = old_token;
    }

    REQUESTS.FIREBASE(body, (data) => {
        localStorage.setItem('token_fcm', token);
    }, (error) => {
        console.error("error : ", error)
    });
}