import { useEffect, useState } from 'react';

import { Modal, DatePicker, Input, Button, Form, notification } from 'antd';

import REQUESTS from '../../../api/requests';

import moment from 'moment';

export default function ReminderModal({
    open, onClose, getReminders
}) {
    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();

    const onFinish = (values) => {
        const { title, message, date } = values;

        let formattedDate = date ? new Date(date) : new Date();

        setLoading(true);

        const body = {
            title,
            message,
            date: formattedDate
        }

        function errorCallback(err) {
            setLoading(false);
            notification.error({
                message: 'Error',
                description: err,
            })
        }

        if (open.id) {
            body.id = open.id;
            REQUESTS.REMINDERS.PUT(
                body,
                (res) => {
                    setLoading(false);

                    form.resetFields();
                    getReminders();
                    onClose();

                    notification.success({
                        message: 'Reminder Updated',
                    })
                },
                errorCallback)
        } else {
            REQUESTS.REMINDERS.POST(
                body,
                (res) => {
                    setLoading(false);

                    form.resetFields();
                    getReminders();
                    onClose();

                    notification.success({
                        message: 'Reminder Created',
                        description: `Reminder set for ${moment(formattedDate).format('YYYY-MM-DD HH:mm')}`,
                    })
                },
                errorCallback)
        }
    };

    useEffect(() => {
        if (open && open.id) {
            form.setFieldsValue({
                title: open.title,
                message: open.message,
                date: open.date ? moment(open.date) : null,
            })
        } else {
            form.resetFields();
            setLoading(false);
        }
    }, [open]);

    return (
        <Modal
            open={open}
            onCancel={onClose}
            title={open?.id ? "Edit Reminder" : "Set Reminder"}
            footer={null}
            destroyOnClose={true}
        >
            <Form
                form={form}
                onFinish={onFinish}
                layout='vertical'
            >
                <Form.Item
                    name='title'
                    label='Title'
                    rules={[{ required: true, message: 'Please enter a title!' }]}
                >
                    <Input name='title' />
                </Form.Item>
                <Form.Item
                    name='message'
                    label='Description'
                    rules={[
                        { required: true, message: 'Please enter a description!' },
                        {
                            max: 255,
                            message: 'Description must be less than 255 characters',
                        }
                    ]}
                >
                    <Input.TextArea maxLength={255} showCount />
                </Form.Item>
                <Form.Item
                    name='date'
                    label='Date and Time'
                    rules={[{ required: true, message: 'Please select a date and time!' }]}
                >
                    <DatePicker
                        showTime={{
                            format: 'HH:mm',
                        }}
                        format="YYYY-MM-DD HH:mm"
                        placeholder="Select Date and Time"
                        style={{ width: '100%' }}
                        disabledDate={(current) => current && current < moment().startOf('day')}
                    />
                </Form.Item>
                <Form.Item className='center-btn'>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading} >
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}
