import { useState } from 'react';
import { useSelector } from 'react-redux';

import { notification, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

export default function PayPalButtonComponent({ id, amount }) {
    const [loading, setLoading] = useState(true);

    const paymentSettings = useSelector(state => state.payment_settings);

    const options = {
        "client-id": paymentSettings.paypal_client_id,
        currency: paymentSettings.currency,
    }

    return paymentSettings && id && (<div>
        {
            loading && <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <Spin
                    indicator={
                        <LoadingOutlined
                            style={{
                                fontSize: 30,
                            }}
                            spin
                        />
                    }
                /></div>
        }
        <div style={{ opacity: loading ? 0 : 1 }}>
            <PayPalScriptProvider options={options} >
                <PayPalButtons
                    fundingSource="card"
                    createOrder={(data, actions) => {
                        return actions.order.create({
                            purchase_units: [{
                                amount: {
                                    currency_code: paymentSettings.currency,
                                    value: amount
                                },
                                custom_id: JSON.stringify({ t: "invoice", id })
                            }],
                            application_context: {
                                shipping_preference: "NO_SHIPPING", // Disable shipping
                                billing_address_collection: "NO_BILLING", // Disable billing address collection
                            },
                        });
                    }}
                    onApprove={(data, actions) => {
                        // console.log("onApprove data:", data)
                        notification.success({
                            description: 'Payment was successful',
                        })
                    }}
                    onInit={() => {
                        // console.log('onInit')
                        setLoading(false)
                    }}
                    onError={(error) => {
                        notification.error({
                            description: `${error}`,
                        });
                    }}
                />
            </PayPalScriptProvider>
        </div>
    </div>)
}
