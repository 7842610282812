import React, { useEffect, useState } from 'react';

import { Table, DatePicker, Button, Row, Tooltip } from 'antd';

import { CalendarOutlined, EditOutlined, PlusOutlined, MessageOutlined } from '@ant-design/icons';

import REQUESTS from '../../api/requests';

import getMyDate from '../../components/getMyDate';

import getComputedSearchProps from '../../components/getColumnSearchProps';

import TableButtons from '../../components/TableButtons';

import UserInfo from './components/UserInfo';

import UserDrawer from './components/UserDrawer';

import styles from './index.module.scss';
import SendSMS from './components/SendSMS';
import TextElipsisTooltip from '../../components/TextElipsisTooltip';

const { RangePicker } = DatePicker;

export default function Users() {
    const [data, setData] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);

    const [limit, setLimit] = useState(10);

    const [total, setTotal] = useState(0);

    const [loading, setLoading] = useState(false);

    const [date, setDate] = useState([]);

    const [show, setShow] = useState({
        smsDrawer: false,
        userDrawer: false,
        userInfo: false
    })

    const [current, setCurrent] = useState(false);

    const [search, setSearch] = useState({});

    const [sort, setSort] = useState(["createdAt", "DESC"]);

    const columns = [
        {
            title: "#",
            dataIndex: "id",
            key: "id",
            align: "center",
            render: (text, record, index) => {
                if (currentPage === 1) {
                    return index + 1;
                } else {
                    return limit * (currentPage - 1) + index + 1;
                }
            },
        },
        {
            title: "First Name",
            align: "center",
            dataIndex: "first_name",
            ...getComputedSearchProps(),
        },
        {
            title: "Last Name",
            align: "center",
            dataIndex: "last_name",
            ...getComputedSearchProps(),
        },
        {
            title: "Email",
            dataIndex: "email",
            align: "center",
            ...getComputedSearchProps("email"),
            render: (text, record) => text || "N/A"
        },
        {
            title: "Phone number",
            align: "center",
            dataIndex: "phone_number",
            ...getComputedSearchProps(),
        },
        {
            title: "Country",
            align: "center",
            dataIndex: "country",
            ...getComputedSearchProps(),
        },
        {
            title: "Address",
            width: 200,
            align: "center",
            dataIndex: "address",
            ...getComputedSearchProps(),
        },
        {
            title: "Registration number",
            align: "center",
            dataIndex: "registration_number",
            sorter: true,
        },
        {
            title: "Invoice Type",
            align: "center",
            dataIndex: "invoice_type",
            sorter: true,
            render: (text, record) => {
                const invoiceTypes = [];
                if (record.invoice_by_email) invoiceTypes.push("Email");
                if (record.invoice_by_paper) invoiceTypes.push("Paper");
                if (record.invoice_by_sms) invoiceTypes.push("SMS");

                return invoiceTypes.length > 0 ? invoiceTypes.join(', ') : "-";
            }
        },
        {
            title: "Note",
            align: "center",
            dataIndex: "note",
            render: (text) => text ? <TextElipsisTooltip text={text} /> : "N/A"
        },
        {
            title: "Created date",
            align: "center",
            dataIndex: "createdAt",
            sorter: true,
            render: (record) => {
                if (record) {
                    return getMyDate(record);
                } else {
                    return "N/A";
                }
            },
            filterDropdown: () => (
                <div
                    style={{
                        padding: 8,
                    }}
                >
                    <RangePicker
                        size="small"
                        onChange={(e, dateString) => setDate(dateString)}
                    />
                </div>
            ),
            filterIcon: (filtered) => (
                <CalendarOutlined />
            ),
        },
        {
            title: "",
            dataIndex: "",
            key: "action",
            render: (text, record) => <div onClick={(e) => e.stopPropagation()}>
                <TableButtons
                    buttons={[
                        {
                            key: "edit",
                            text: "Edit",
                            icon: <EditOutlined style={{ fontSize: 16 }} />,
                        },
                        {
                            key: "sms",
                            text: "Send SMS",
                            icon: <MessageOutlined style={{ fontSize: 16 }} />
                        },
                    ]}
                    handleMenuClick={(e) => handleMenuClick(e, record)}
                />
            </div >
        }
    ];

    const handleMenuClick = (e, item) => {
        switch (e.key) {
            case 'edit':
                setShow({ ...show, userDrawer: true })
                setCurrent(item);
                break;
            case 'sms':
                setCurrent(item);
                setShow({ ...show, smsDrawer: true })
                break;
            default:
                break;
        }
    }

    const getUsers = () => {
        setLoading(true);

        const query = {
            limit,
            page: currentPage,
            sort: JSON.stringify(sort),
        };

        if (search.email || search.first_name || search.last_name || search.country || search.address || search.phone_number) {

            const searchValue = search.email || search.first_name || search.last_name || search.country || search.address || search.phone_number;
            query.search = {}

            if (search.email) {
                query.search.email = searchValue[0];
            }

            if (search.first_name) {
                query.search.first_name = searchValue[0];
            }

            if (search.last_name) {
                query.search.last_name = searchValue[0];
            }

            if (search.country) {
                query.search.country = searchValue[0];
            }

            if (search.address) {
                query.search.address = searchValue[0];
            }

            if (search.phone_number) {
                query.search.phone_number = searchValue[0];
            }

            query.search = JSON.stringify(query.search);
        }

        if (date && date[0]) {
            query.between = JSON.stringify({
                createdAt: {
                    from: date[0],
                    to: date[1],
                },
            })
        }

        REQUESTS.USERS.GET(query, (data) => {
            setData(data.rows);
            setTotal(data.count);
            setLoading(false);
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setSearch(filters);
        if (sorter.field) {
            setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
        }
        setCurrentPage(pagination.current);
        setLimit(pagination.pageSize);
    }

    useEffect(() => {
        let int = setTimeout(() => {
            getUsers();
        }, 500);

        return () => clearTimeout(int);
    }, [date, search, sort, currentPage, limit]);

    return (
        <div>
            <Row justify="space-between" align='middle'>
                <h1 className="page-title">Users</h1>
                <Button type='primary'
                    onClick={() => {
                        setShow({ ...show, userDrawer: true })
                    }}>
                    <PlusOutlined />
                </Button>
            </Row>
            <Table
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (e) => {
                            setShow({ ...show, userInfo: true })
                            setCurrent(record);
                        },
                    };
                }}
                className={styles['table-rows']}
                loading={loading}
                rowKey="id"
                columns={columns}
                dataSource={data}
                onChange={handleTableChange}
                pagination={{
                    position: ["bottomCenter"],
                    current: currentPage,
                    total: total,
                    pageSize: limit,
                    showSizeChanger: true,
                }}
                size="small"
                scroll={{ x: "max-content" }}
            />
            <UserInfo
                current={current}
                open={show.userInfo}
                onClose={() => {
                    setShow({ ...show, userInfo: false })
                    setCurrent(null);
                }}
            />
            <UserDrawer
                open={show.userDrawer}
                onClose={() => {
                    setShow({ ...show, userDrawer: false })
                    setCurrent(null);
                }}
                current={current}
                getData={getUsers}
            />
            <SendSMS
                userId={current?.id}
                open={show.smsDrawer}
                onClose={() => {
                    setShow({ ...show, smsDrawer: false })
                    setCurrent(null);
                }}
            />
        </div>
    )
}
