import { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import {
  Button,
  Form,
  Input,
  Upload,
  Image,
  notification,
  Switch,
} from "antd";
import { selectTranslation } from "../../../features/Translation/TranslationSlice";
import { selectwidgets } from "../../../features/widgets/selector";

import { editAppInfo } from "../../../features/appInfoSlice/AppInfoSlice";
import REQUESTS from "../../../api/requests";
import TEXT from "../../../config/text";

import classes from "../style/branding.module.scss";
import { selectUser } from "../../../features/DataUserProfile/DataUserProfileSlise";

const Branding = () => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [application, setApplication] = useState(null);
  const [currencies, setCurrencies] = useState();

  const [fileList, setFileList] = useState([]);
  const [fileListBackimg, setFileListBackimg] = useState([]);
  const [favicon, setFavicon] = useState([]);
  const [url, setUrl] = useState();
  const [urlBack, setUrlBack] = useState();
  const [urlFavicon, setUrlFavicon] = useState();

  const [underConstruction, setUnderConstruction] = useState(false);
  const [resellerLoginBackground, setResellerLoginBackground] = useState([]);
  const [urlResellerLoginBackground, setUrlResellerLoginBackground] =
    useState("");

  const [adminLoginBackground, setAdminLoginBackground] = useState([]);
  const [urlAdminLoginBackground, setUrlAdminLoginBackground] = useState("");

  const [sidebarBackgroundColor, setSidebarBackgroundColor] = useState('');
  const [sidebarItemActiveBackground, setSidebarItemActiveBackground] = useState('');
  const [sidebarItemActiveColor, setSidebarItemActiveColor] = useState('');
  const [sidebarItemColor, setSidebarItemColor] = useState('');

  const translation = useSelector(selectTranslation);
  const widgets = useSelector(selectwidgets);

  const { admin_permissions } = useSelector(selectUser)

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      const myurl = window.URL
        ? window.URL.createObjectURL(file)
        : window.webkitURL.createObjectURL(file);
      setUrl(myurl);
      if (file.type !== "image/jpeg" && file.type !== "image/png") {
        setFileList([]);
        setUrl(null);
        openNotification("You can only upload JPG/PNG file!");
      }
      return false;
    },
    fileList,
  };

  const propsBack = {
    onRemove: (file) => {
      const index = fileListBackimg.indexOf(file);
      const newFileList = fileListBackimg.slice();
      newFileList.splice(index, 1);
      setFileListBackimg(newFileList);
    },
    beforeUpload: (file) => {
      // setFileListBackimg([...fileList, file]);
      setFileListBackimg([file]);
      const myurl = window.URL
        ? window.URL.createObjectURL(file)
        : window.webkitURL.createObjectURL(file);
      setUrlBack(myurl);
      if (file.type !== "image/jpeg" && file.type !== "image/png") {
        setFileListBackimg([]);
        setUrlBack(null);
        openNotification("You can only upload JPG/PNG file!");
      }

      return false;
    },
    fileListBackimg,
  };

  const propsFavicon = {
    onRemove: (file) => {
      const index = favicon.indexOf(file);
      const newFileList = favicon.slice();
      newFileList.splice(index, 1);
      setFavicon(newFileList);
    },
    beforeUpload: (file) => {
      // setFavicon([...fileList, file]);
      setFavicon([file]);
      const myurl = window.URL
        ? window.URL.createObjectURL(file)
        : window.webkitURL.createObjectURL(file);
      setUrlFavicon(myurl);
      if (file.type !== "image/jpeg" && file.type !== "image/png") {
        setFavicon([]);
        setUrlFavicon(null);
        openNotification("You can only upload JPG/PNG file!");
      }
      return false;
    },
    favicon,
  };

  const propsresellerLoginBackground = {
    onRemove: (file) => {
      const index = resellerLoginBackground.indexOf(file);
      const newFileList = resellerLoginBackground.slice();
      newFileList.splice(index, 1);
      setResellerLoginBackground(newFileList);
    },
    beforeUpload: (file) => {
      // setFavicon([...fileList, file]);
      setResellerLoginBackground([file]);
      const myurl = window.URL
        ? window.URL.createObjectURL(file)
        : window.webkitURL.createObjectURL(file);
      setUrlResellerLoginBackground(myurl);
      if (file.type !== "image/jpeg" && file.type !== "image/png") {
        setResellerLoginBackground([]);
        setUrlResellerLoginBackground(null);
        openNotification("You can only upload JPG/PNG file!");
      }
      return false;
    },
    resellerLoginBackground,
  };

  const propsAdminLoginBackground = {
    onRemove: (file) => {
      const index = adminLoginBackground.indexOf(file);
      const newFileList = adminLoginBackground.slice();
      newFileList.splice(index, 1);
      setAdminLoginBackground(newFileList);
    },
    beforeUpload: (file) => {
      // setFavicon([...fileList, file]);
      setAdminLoginBackground([file]);
      const myurl = window.URL
        ? window.URL.createObjectURL(file)
        : window.webkitURL.createObjectURL(file);
      setUrlAdminLoginBackground(myurl);
      if (file.type !== "image/jpeg" && file.type !== "image/png") {
        setAdminLoginBackground([]);
        setUrlAdminLoginBackground(null);
        openNotification("You can only upload JPG/PNG file!");
      }
      return false;
    },
    resellerLoginBackground,
  };

  const openNotification = (message) => {
    const args = {
      message: "application",
      description: message,
      duration: 5,
    };
    notification.open(args);
  };

  const getApplication = () => {
    const collback = (data) => {
      dispatch(editAppInfo(data));
      setApplication(data);
      setUnderConstruction(data.under_construction);
      setSidebarBackgroundColor(data.sidebar_background);
      setSidebarItemActiveBackground(data.sidebar_item_active_background);
      setSidebarItemActiveColor(data.sidebar_item_active_color);
      setSidebarItemColor(data.sidebar_item_color);
    };

    REQUESTS.APP_INFO.GET(collback);
  };

  const getcurrencies = () => {
    const collback = (data) => {
      setCurrencies(data);
    };

    REQUESTS.APP_INFO.CURRENCIES(collback);
  };

  const onFinish = (values) => {
    setLoading(true);
    const formData = new FormData();

    formData.append("id", application?.id);
    formData.append("header_text", values.header_text);
    formData.append("text", values.text);
    if (values.accept_text.length) {
      formData.append("accept_text", values.accept_text);
    }

    formData.append("under_construction", underConstruction);

    formData.append("sidebar_background", sidebarBackgroundColor);
    formData.append("sidebar_item_active_background", sidebarItemActiveBackground);
    formData.append("sidebar_item_active_color", sidebarItemActiveColor);
    formData.append("sidebar_item_color", sidebarItemColor);
    formData.append("sidebar_logo_size", values.sidebar_logo_size);

    if (fileList) {
      fileList.forEach((file) => {
        formData.append("logo", file);
      });
      if (fileListBackimg) {
        fileListBackimg.forEach((newfile) => {
          formData.append("background_image", newfile);
        });
      }

      if (favicon) {
        favicon.forEach((newfile) => {
          formData.append("favicon", newfile);
        });
      }

      if (resellerLoginBackground) {
        resellerLoginBackground.forEach((newfile) => {
          formData.append("reseller_login_background", newfile);
        });
      }

      if (adminLoginBackground) {
        adminLoginBackground.forEach((newfile) => {
          formData.append("admin_login_background", newfile);
        });
      }
    }

    function callback(data) {
      setLoading(false);
      setFileList([]);
      setFileListBackimg([]);
      setFavicon([]);
      setAdminLoginBackground([]);
      setResellerLoginBackground([]);
      getApplication();
      openNotification("application update");
    }

    function errorCallback(err) {
      setLoading(false);

      openNotification();
    }

    REQUESTS.APP_INFO.UPDATE(formData, callback, errorCallback);
  };

  useEffect(() => {
    getApplication();
    getcurrencies();
  }, []);

  useEffect(() => {

    if (application) {
      form.setFields([
        {
          name: "header_text",
          value: application?.header_text,
        },

        {
          name: "text",
          value: application?.text,
        },

        {
          name: "accept_text",
          value: application?.accept_text,
        },
        {
          name: "sidebar_logo_size",
          value: application?.sidebar_logo_size,
        },
      ]);

      setSidebarBackgroundColor(application.sidebar_background);
      setSidebarItemActiveBackground(application.sidebar_item_active_background);
      setSidebarItemActiveColor(application.sidebar_item_active_color);
      setSidebarItemColor(application.sidebar_item_color);
    }
  }, [application]);

  return (
    <Form
      form={form}
      name="profile"
      layout="vertical"
      onFinish={onFinish}
    >
      <div className={classes["app_info"]}>
        <div className="app_info_img">
          <div className="settings__branding-section__form-item">
            <Form.Item
              label={
                translation["Application backdrop"] ||
                TEXT["Application backdrop"]
              }
            >
              <div className="app_upload_form">
                <Image
                  className={classes["branding_upload_img"]}
                  src={url ? url : application?.logo}
                />
                <Upload {...props} fileList={fileList}>
                  <Button className={classes["uload_button"]} type="primary">
                    Upload
                  </Button>
                </Upload>
              </div>
            </Form.Item>
          </div>

          <div className="settings__branding-section__form-item">
            <Form.Item
              label={
                translation["Application background image"] ||
                TEXT["Application background image"]
              }
            >
              <div className="app_upload_form">
                <Image
                  className={classes["branding_upload_img"]}
                  src={urlBack ? urlBack : application?.background_image}
                />
                <Upload {...propsBack} fileList={fileListBackimg}>
                  <Button className={classes["uload_button"]} type="primary">
                    Upload
                  </Button>
                </Upload>
              </div>
            </Form.Item>
          </div>
          <div className="settings__branding-section__form-item">
            <Form.Item
              label={
                translation["Application Favicon"] ||
                TEXT["Application Favicon"]
              }
            >
              <div className="app_upload_form">
                <Image
                  className={classes["branding_upload_img"]}
                  src={urlFavicon ? urlFavicon : application?.favicon}
                />

                <Upload {...propsFavicon} fileList={favicon}>
                  <Button className={classes["uload_button"]} type="primary">
                    Upload
                  </Button>
                </Upload>
              </div>
            </Form.Item>
          </div>
          <div className="settings__branding-section__form-item">
            <Form.Item
              label={
                translation["Reseller login background"] ||
                TEXT["Reseller login background"]
              }
            >
              <div className="app_upload_form">
                <Image
                  className={classes["branding_upload_img"]}
                  src={
                    urlResellerLoginBackground
                      ? urlResellerLoginBackground
                      : application?.reseller_login_background
                  }
                />

                <Upload
                  {...propsresellerLoginBackground}
                  fileList={resellerLoginBackground}
                >
                  <Button className={classes["uload_button"]} type="primary">
                    Upload
                  </Button>
                </Upload>
              </div>
            </Form.Item>
          </div>

          <div className="settings__branding-section__form-item">
            <Form.Item
              label={
                translation["Admin login background"] ||
                TEXT["Admin login background"]
              }
            >
              <div className="app_upload_form">
                <Image
                  className={classes["branding_upload_img"]}
                  src={
                    urlAdminLoginBackground
                      ? urlAdminLoginBackground
                      : application?.admin_login_background
                  }
                />

                <Upload
                  {...propsAdminLoginBackground}
                  fileList={adminLoginBackground}
                >
                  <Button className={classes["uload_button"]} type="primary">
                    Upload
                  </Button>
                </Upload>
              </div>
            </Form.Item>
          </div>
        </div>
        <div className={classes["branding__text-form"]}>
          <div className={classes["inputs-col"]}>
            <Form.Item
              className={classes["ant-form-item-required"]}
              label={translation["Header text"] || TEXT["Header text"]}
              name="header_text"
              rules={[
                {
                  required: true,
                  message: "Please input header text",
                },
              ]}
            >
              <Input className={classes["ant_input"]} />
            </Form.Item>

            <Form.Item
              name="text"
              label={
                translation["Enter activation text"] ||
                TEXT["Enter activation text"]
              }
            >
              <Input className={classes["ant_input"]} />
            </Form.Item>

            <Form.Item
              name="accept_text"
              label={
                translation["Enter accept text"] || TEXT["Enter accept text"]
              }
            >
              <Input.TextArea
                className={classes["ant_input"]}
                style={{
                  resize: "none",
                }}
                rows={15}
              />
            </Form.Item>
            {widgets?.editor?.enabled === "true" ? (
              <Form.Item>
                <a
                  target={"_blank"}
                  className={classes["advanced_settings_link"]}
                  href={`${application?.web_host
                    }/#/home?token=${localStorage.getItem("TOKEN")}`}
                >
                  {translation["Advanced web settings"] ||
                    TEXT["Advanced web settings"]}
                </a>
              </Form.Item>
            ) : (
              ""
            )}
            <Form.Item>
              Under construction page{" "}
              <Switch
                checked={underConstruction}
                onChange={(value) => {
                  setUnderConstruction(value);
                }}
              />
            </Form.Item>
          </div>
          <div className={classes["inputs-col"]}>
            <Form.Item
              label={translation["Sidebar logo size"] || TEXT["Sidebar logo size"]}
              name="sidebar_logo_size"
            >
              <Input type={"number"} min="0" />
            </Form.Item>
            <div className={classes['color-picker']}>
              <input
                type="color"
                id="sidebar_background"
                name="sidebar_background"
                value={sidebarBackgroundColor}
                onChange={(e) => { setSidebarBackgroundColor(e.target.value) }} />
              <label for="sidebar_background">Sidebar background color</label>
            </div>
            <div className={classes['color-picker']}>
              <input
                type="color"
                id="sidebar_item_active_background"
                name="sidebar_item_active_background"
                value={sidebarItemActiveBackground}
                onChange={(e) => { setSidebarItemActiveBackground(e.target.value) }}
              />
              <label for="sidebar_background">Sidebar item active background</label>
            </div>
            <div className={classes['color-picker']}>
              <input
                type="color"
                id="sidebar_item_active_color"
                name="sidebar_item_active_color"
                value={sidebarItemActiveColor}
                onChange={(e) => { setSidebarItemActiveColor(e.target.value) }} />
              <label for="sidebar_background">Sidebar item active color</label>
            </div>
            <div className={classes['color-picker']}>
              <input
                type="color"
                id="sidebar_item_color"
                name="sidebar_item_color"
                value={sidebarItemColor}
                onChange={(e) => { setSidebarItemColor(e.target.value) }} />
              <label for="sidebar_item_color">Sidebar item color</label>
            </div>
          </div>
        </div>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className={classes["branding_save"]}
            loading={loading}
            disabled={
              admin_permissions &&
              admin_permissions["settings"] &&
              !admin_permissions["settings"]["Update application/branding"]}
          >
            {translation["Save"] || TEXT["Save"]}
          </Button>
        </Form.Item>

      </div>
    </Form>
  );
};

export default Branding;
