
import { Drawer } from 'antd';

import useResize from '../../../hooks/use-resize';
import UserDevices from './UserDevices';
import Invoices from './Invoices';

export default function UserInfo({ current, open, onClose }) {
    const windowWidth = useResize();

    return (
        <Drawer
            title=""
            open={open}
            onClose={() => onClose()}
            width={windowWidth > 900 ? 900 : "90%"}
            destroyOnClose={true}
        >
            <UserDevices current={current} />
            <Invoices user={current} />
        </Drawer>
    )
}
